import { Col, ColProps, Row, RowProps } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ActivityInvite from './ActivityEvents';
import Map from './Map';
import ShipmentGraph from './ShipmentGraph';
import ShipmentInvite from './ShipmentInvite';

const colMapProps: ColProps = { xs: 24, sm: 24, md: 12, lg: 16 };
const colShipInviteProps: ColProps = { xs: 24, sm: 24, md: 12, lg: 8 };
const colhalfProps: ColProps = { xs: 24, sm: 24, md: 12, lg: 12 };
const rowProps: RowProps = { gutter: [24, 24] };
const Dashboard: FC = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });

  return (
    <div className="dashboardcontent">
      <Row {...rowProps}>
        <Col {...colMapProps}>
          <Map />
        </Col>
        <Col {...colShipInviteProps}>
          <ShipmentInvite t={t} />
        </Col>
        <Col {...colhalfProps}>
          <ShipmentGraph t={t} />
        </Col>
        <Col {...colhalfProps}>
          <ActivityInvite t={t} />
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(Dashboard);
