import { AccountInfo } from '@azure/msal-browser';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Libraries, LoadScript } from '@react-google-maps/api';
import { message } from 'antd';
import { Loading } from 'components';
import { scopes, signinRequest } from 'config/authConfig';
import { useTranslationContext } from 'contexts/TranslationProvider';
import CreateAccount from 'pages/CreateAccount';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { getAccount, useRedeemInvite } from 'services/api';
import {
  getExistingUserSession,
  getInviteIdSession,
  removeInviteIdSession,
  setExistingUserSession,
} from 'utils';
import { getVariableFromEnv } from 'utils/config';
import { getErrorJSON } from 'utils/errorHandler';
import { initLanguageSelection } from 'utils/language';
import { AuthenticationContextValue, AuthenticationProviderProps, CurrentUser } from './typings';

const gmapsAPIKey = getVariableFromEnv('GMAPS_API_KEY');

const libraries: Libraries = ['places'];

const AuthenticationContextDefault: AuthenticationContextValue = {
  currentUser: undefined,
  isAuthenticated: false,
  isAPIAuthenticated: false,
  setIsAPIAuthenticated: () => {},
  setIsAuthProcess: () => {},
  signin: () => {},
  signout: () => {},
  refreshToken: () => {},
  langCode: '',
  setLangCode: () => '',
};

const AuthenticationContext = React.createContext<AuthenticationContextValue>(
  AuthenticationContextDefault,
);

export const AuthenticationProvider: FC<AuthenticationProviderProps> = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal();
  const queryClient = useQueryClient();
  const redeemInvite = useRedeemInvite(queryClient);
  const account = useAccount(accounts[0] || {});
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [isAPIAuthenticated, setIsAPIAuthenticated] = useState<boolean>(true);
  const [langCode, setLangCode] = useState<string>('');
  const { setLocale } = useTranslationContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isAuthProcess, setIsAuthProcess] = useState<boolean>(true);
  const checkUserAuth = useCallback(
    async (err: any) => {
      const errRes = getErrorJSON(err);
      if (errRes?.status === 401 || errRes?.status === 404) {
        setIsAPIAuthenticated(false);
      } else {
        setIsAPIAuthenticated(true);
      }
    },
    [setIsAPIAuthenticated],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const clearSessions = useCallback(() => {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }, []);
  /**
   *  refresh token method will reset the session refresh token present in the session storage and will call the acquireTokenSilent method to get a new refresh token and access token for the user.
   */
  const refreshToken = useCallback(() => {
    try {
      instance.logout();
      setIsAPIAuthenticated(true);
      return null;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      return null;
    }
  }, [instance, setIsAPIAuthenticated]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkInvite = useCallback(async () => {
    try {
      const inviteId = getInviteIdSession();
      const isExistingUser = getExistingUserSession();
      if (inviteId) {
        await redeemInvite.mutateAsync(inviteId);
        message.success('Invite redeemed successfully, now login to continue');
        removeInviteIdSession();
        if (isExistingUser) {
          setExistingUserSession('redeem');
        } else {
          instance.logout();
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('checkInviteError', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const acquireToken = useCallback(
    async (accnt: AccountInfo) => {
      try {
        setIsAuthProcess(true);
        const tokenResponse = await instance.acquireTokenSilent({
          scopes,
          account: accnt,
        });
        if (tokenResponse) {
          await checkInvite();

          const tokenDetails = tokenResponse?.account?.idTokenClaims;
          await getAccount();
          await initLanguageSelection(tokenDetails?.sub || '', setLocale, setLangCode);
          setCurrentUser({
            id: tokenDetails?.sub || '',
            displayName: `${tokenDetails?.given_name || ''} ${tokenDetails?.family_name || ''}`,
            mail: String(tokenDetails?.emails?.[0] || ''),
          });
          setIsAPIAuthenticated(true);
        }
        setIsAuthProcess(false);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('acquire token error: ', err);
        checkUserAuth(err);
        setIsAuthProcess(false);
      }
    },
    [instance, checkInvite, setLocale, checkUserAuth],
  );

  useEffect(() => {
    if (account) {
      acquireToken(account);
    } else {
      setLangCode('en');
    }
  }, [account, acquireToken]);
  const isAuthenticated = useIsAuthenticated();

  const signin = useCallback(() => {
    if (!isAuthenticated) {
      instance.loginRedirect(signinRequest);
    }
  }, [instance, isAuthenticated]);

  const signout = useCallback(() => {
    instance.logout();
  }, [instance]);

  const contextValue: AuthenticationContextValue = useMemo(
    () => ({
      currentUser,
      isAuthenticated,
      isAPIAuthenticated,
      signin,
      signout,
      setIsAPIAuthenticated,
      setIsAuthProcess,
      refreshToken,
      langCode,
      setLangCode,
    }),
    [
      currentUser,
      isAuthenticated,
      isAPIAuthenticated,
      signin,
      signout,
      setIsAPIAuthenticated,
      setIsAuthProcess,
      refreshToken,
      langCode,
      setLangCode,
    ],
  );
  const renderChildren = useCallback(() => {
    if (inProgress === 'login' || inProgress === 'handleRedirect') {
      return <div />;
    }
    if (isAuthenticated && !isAPIAuthenticated) {
      return <CreateAccount />;
    }
    return children;
  }, [inProgress, isAuthenticated, isAPIAuthenticated, children]);

  return (
    <AuthenticationContext.Provider value={contextValue}>
      <Loading isLoading={langCode === ''} fullOpacity />
      {langCode !== '' && (
        <LoadScript
          googleMapsApiKey={gmapsAPIKey}
          libraries={libraries}
          language={langCode}
          loadingElement={<Loading hideSpin />}
          region="cn"
        >
          {renderChildren()}
        </LoadScript>
      )}
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticationContext = (): AuthenticationContextValue => {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error('AuthenticationContext must be used within a AuthenticationContextProvider');
  }

  return context;
};
