import { Button, List, Typography } from 'antd';
import { useWindowSize } from 'hooks';
import moment from 'moment';
import { useEventsStore } from 'pages/Events/hooks';
import useShipmentStore from 'pages/Shipments/hooks';
import VirtualList, { ListProps } from 'rc-virtual-list';
import React, { FC, useCallback, useMemo } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useInfiniteOtherSystemsShipments } from 'services/api';
import { InboundShipmentResponse } from 'services/api/client/src';
import { dateLocaleFormat } from 'utils';
import EmptyNewStateShipments from './components/EmptyNewStateShipments';
import styles from './index.module.less';
import { InboundShipmentProps } from './typings';

const InboundOtherSystemsShipments: FC<InboundShipmentProps> = ({ t }) => {
  const [, height] = useWindowSize();
  const navigate = useNavigate();
  const { setAdvancedSettings, setDataEntryMethod } = useEventsStore();
  const { setSelectedShipments, processingReceiveIds } = useShipmentStore();

  const { data, fetchNextPage } = useInfiniteOtherSystemsShipments({
    pageNumber: 1,
    pageSize: 10,
    direction: 'Inbound',
    status: ['Pending'],
    sortBy: '-eventDate',
  });

  const shouldDisableReceiveNavigation = useCallback(
    (record?: InboundShipmentResponse) =>
      processingReceiveIds?.includes(record?.shipmentId!) ?? false,
    [processingReceiveIds],
  );

  const records = useMemo<Array<InboundShipmentResponse>>(
    () =>
      data?.pages?.reduce(
        (acc, page) => [...acc, ...(page?.results || [])],
        [] as Array<InboundShipmentResponse>,
      ) || [],
    [data],
  );

  const scrollHeight = useMemo(() => {
    // amount of pixels equivalent to 40vh
    const vh = Math.floor(height * 0.38) - 25;
    if (vh < 170) {
      return 195;
    }
    return vh;
  }, [height]);

  const onScroll = useCallback<NonNullable<ListProps<InboundShipmentResponse>['onScroll']>>(
    (e) => {
      const threshold = 1;
      const diff = e.currentTarget.scrollHeight - e.currentTarget.scrollTop;
      const thresholdReached = Math.floor(diff) === scrollHeight * threshold;
      if (thresholdReached) {
        fetchNextPage();
      }
    },
    [fetchNextPage, scrollHeight],
  );

  const onReceive = useCallback(
    (record: InboundShipmentResponse) => {
      setDataEntryMethod('manual');
      setAdvancedSettings(false);
      const productsSearchParams = createSearchParams({
        shipmentId: record?.shipmentId || '',
        shipmentType: 'External',
        tradePartnerUrn: record?.destinationCompanyUrn || '',
        locationUrn: record?.destinationLocationUrn || '',
        eventDate: dateLocaleFormat(record?.eventDate || new Date()) || '',
        isOtherSystem: String(true),
      });
      // sets signle shippment details in store
      setSelectedShipments([record as InboundShipmentResponse]);
      navigate({
        pathname: `/products/${record?.shipmentId}/events/receive`,
        search: record ? `?${productsSearchParams}` : undefined,
      });
    },
    [setDataEntryMethod, setAdvancedSettings, setSelectedShipments, navigate],
  );

  const renderList = useCallback(
    () =>
      records?.length > 0 ? (
        <VirtualList<InboundShipmentResponse>
          data={records}
          height={scrollHeight}
          itemHeight={73}
          itemKey="shipmentId"
          onScroll={onScroll}
        >
          {(record: InboundShipmentResponse) => (
            <List.Item
              key={record.shipmentId}
              actions={[
                <Button
                  type="link"
                  size="small"
                  disabled={shouldDisableReceiveNavigation(record)}
                  onClick={() => onReceive(record)}
                >
                  {t('shipment_invite.receive')}
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={
                  <Typography.Text ellipsis>
                    {record?.originCompanyName || 'Origin Name'}
                  </Typography.Text>
                }
                description={
                  <>
                    <Typography.Text ellipsis className={styles.listdesc} type="secondary">
                      {`Number of Products: ${record?.numberOfProducts || 0}`}
                    </Typography.Text>
                    <br />
                    <Typography.Text ellipsis className={styles.listdesc} type="secondary">
                      {`Ship Date: ${moment(record?.eventDate).format('YYYY-MM-DD')}`}
                    </Typography.Text>
                    <br />
                    <Typography.Text ellipsis className={styles.listdesc} type="secondary">
                      {`Purchase Order: ${record?.purchaseOrderNumber || '-'}`}
                    </Typography.Text>
                  </>
                }
              />
            </List.Item>
          )}
        </VirtualList>
      ) : (
        <EmptyNewStateShipments t={t} isWholechain={false} />
      ),
    [records, scrollHeight, onScroll, onReceive, t, shouldDisableReceiveNavigation],
  );
  return <List className={styles.list}>{renderList()}</List>;
};
export default React.memo(InboundOtherSystemsShipments);
