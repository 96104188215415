import { ProColumns } from '@ant-design/pro-table';
import { Popover, Row, TablePaginationConfig, Tag, Tooltip, Typography } from 'antd';
import { Actions, SingleColumnTags } from 'components';
import GTable from 'components/GTable';
import useAttributeStore from 'components/Settings/DefinedAttributes/hooks';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import useLocationStore, {
  filterDefaultAttribute,
  getDefaultValue,
  getSelectOptions,
} from 'pages/Network/hook';
import React, { FC, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { ListAttributesOptionalParams } from 'services/api/client/src';
import useAttributes from 'services/api/useAttributes';
import { AttributeCols, FilterArrayProp } from '../../../typings';
import styles from './index.module.less';
import { AttributeProps } from './typings';

type ColumnsType = {
  fieldSearchfilter: ProColumns;
  propertyNameSearchfilter: ProColumns;
  fieldTypeSearchfilter: ProColumns;
  valuesSearchfilter: ProColumns;
  defaultValueSearchfilter: ProColumns;
  t: TFunction<'pages', 'network.attribute_table.columns'>;
  handleActionsMenuClick: (actionItemKey: string, actionPayload?: AttributeCols) => void;
  stdFilters?: Array<FilterArrayProp>;
  namespaceFilters?: Array<FilterArrayProp>;
  hasAdvancedSettings?: boolean;
};

const columns = ({
  t,
  fieldSearchfilter,
  propertyNameSearchfilter,
  fieldTypeSearchfilter,
  valuesSearchfilter,
  defaultValueSearchfilter,
  handleActionsMenuClick,
  stdFilters,
  namespaceFilters,
  hasAdvancedSettings,
}: ColumnsType): ProColumns<AttributeCols>[] => [
  {
    title: t('field'),
    dataIndex: 'field',
    ...fieldSearchfilter,
  },
  {
    title: t('property_name'),
    dataIndex: 'propertyName',
    ...propertyNameSearchfilter,
  },
  {
    title: t('field_type'),
    dataIndex: 'fieldType',
    ...fieldTypeSearchfilter,
  },
  {
    title: t('values'),
    dataIndex: 'value',
    ...valuesSearchfilter,
    render: (text, options) =>
      options.fieldType === 'Dropdown' || options.fieldType === 'RadioButton' ? (
        <SingleColumnTags
          values={options?.values?.map((item) => String(item?.value || '')) || []}
          columnTitle="Values"
          modalTitle={options?.field || ''}
        />
      ) : null,
  },
  {
    title: t('default_value'),
    dataIndex: 'defaultValue',
    ...defaultValueSearchfilter,
    render: (text, options) => (
      <Popover>
        {Array.isArray(options?.defaultValue) ? (
          <Tooltip
            placement="bottom"
            title={options?.defaultValue?.map((item) => (
              <div>
                {item.value === '' ? (
                  <Row>
                    <Typography.Text
                      className={styles.defaultvalue}
                    >{`${item?.locationName} : `}</Typography.Text>
                    <Typography.Text className={styles.nodefaultvalue}>
                      {`${t('no_default_value')}`}
                    </Typography.Text>
                  </Row>
                ) : (
                  <Typography.Text
                    className={styles.defaultvalue}
                  >{`${item?.locationName} : ${item?.value}`}</Typography.Text>
                )}
              </div>
            ))}
          >
            <Tag>
              {t('default_value_tag', {
                valueCount: options?.defaultValue?.filter((item) => item?.value !== '')?.length,
              })}
            </Tag>
          </Tooltip>
        ) : (
          options?.defaultValue || ''
        )}
      </Popover>
    ),
  },
  {
    title: t('std'),
    dataIndex: 'stdOption',
    hideInTable: !hasAdvancedSettings,
    filters: stdFilters,
  },
  {
    title: t('namespace'),
    dataIndex: 'namespace',
    hideInTable: !hasAdvancedSettings,
    filters: namespaceFilters,
  },
  {
    title: '',
    hideInSetting: true,
    render: (text, record) => (
      <Actions
        className="actions"
        buttonProps={{
          ghost: true,
          size: 'small',
        }}
        items={[
          {
            key: 'edit',
            label: t('edit'),
          },
        ]}
        onClick={handleActionsMenuClick}
        actionPayload={record}
      />
    ),
    fixed: 'right',
    width: 130,
  },
];

const AttributeTable: FC<AttributeProps> = ({ companyLocations, onEditAttribute }) => {
  const { isLocationView, id, name } = useLocationStore();
  const locationIds = useMemo(
    () => (isLocationView ? [String(id)] : companyLocations?.map((item) => item?.id || '') || []),
    [companyLocations, id, isLocationView],
  );
  const [param, setParam] = useState<ListAttributesOptionalParams>({
    pageNumber: 1,
    pageSize: 5,
  });
  const {
    data: dataTemplateAttributes,
    isLoading,
    refetch,
  } = useAttributes({ locationIds, ...param });
  const hasAdvancedSettings = useAttributeStore((state) => state.hasAdvancedSettings);
  const { t } = useTranslation('pages', { keyPrefix: 'network.attribute_table.columns' });

  const records: Array<AttributeCols> = useMemo(
    () =>
      dataTemplateAttributes?.results?.map((item) => ({
        key: item?.id || '',
        id: item?.id || '',
        field: item?.name,
        propertyName: item.fieldProperties?.fields?.propertyName,
        fieldType: item.fieldProperties?.fields?.fieldType,
        stdOption: item?.fieldProperties?.location || '',
        namespace: item?.fieldProperties?.namespace?.name || '',
        values:
          item?.fieldProperties?.fields?.fieldType === 'Dropdown' ||
          item.fieldProperties?.fields?.fieldType === 'RadioButton'
            ? getSelectOptions(item?.fieldProperties?.values?.valueOptions || [])
            : [],
        defaultValue: isLocationView
          ? getDefaultValue(String(id), item?.fieldProperties?.values?.defaultValues || []) || ''
          : filterDefaultAttribute(
              locationIds,
              item?.fieldProperties?.values?.defaultValues || [],
            ) || '',
        itemProps: item,
      })) || [],
    [dataTemplateAttributes?.results, id, isLocationView, locationIds],
  );

  const stdFilters: Array<FilterArrayProp> = [
    { value: 'ILMD', text: 'ILMD' },
    { value: 'Extension', text: 'Extension' },
  ];

  const namespaceFilters: Array<FilterArrayProp> = [
    { value: 'GDST', text: 'GDST' },
    { value: 'CBV', text: 'CBV' },
  ];

  const { filters: fieldSearchfilter } = useTableSearchFilter({
    title: t('field'),
  });
  const { filters: propertyNameSearchfilter } = useTableSearchFilter({
    title: t('property_name'),
  });

  const { filters: fieldTypeSearchfilter } = useTableSearchFilter({
    title: t('field_type'),
  });
  const { filters: valuesSearchfilter } = useTableSearchFilter({
    title: t('values'),
  });
  const { filters: defaultValueSearchfilter } = useTableSearchFilter({
    title: t('default_value'),
  });

  const onTableChange = (
    params: TablePaginationConfig,
    filter: Record<string, Array<string> | null>,
  ) => {
    const fieldFilter = filter?.field
      ? {
          fieldName: filter?.field?.[0] as string,
        }
      : {};
    const propertyNameFilter = filter?.propertyName
      ? {
          propertyName: filter?.propertyName?.[0] as string,
        }
      : {};
    const fieldTypeFilter = filter?.fieldType
      ? {
          fieldType: filter?.fieldType?.[0] as string,
        }
      : {};
    const valueTypeFilter = filter?.value
      ? {
          values: filter?.value?.[0] as string,
        }
      : {};
    const defaultValueTypeFilter = filter?.defaultValue
      ? {
          defaultValue: filter?.defaultValue?.[0] as string,
        }
      : {};
    const stdOptionFilter = filter?.stdOption
      ? {
          propertyLocation: filter?.stdOption?.[0] as string,
        }
      : {};
    const namespaceFilter = filter?.namespace
      ? {
          namespace: filter?.namespace?.[0] as string,
        }
      : {};
    // @ts-ignore
    setParam({
      pageNumber: params?.current || 1,
      pageSize: params?.pageSize || 5,
      ...fieldFilter,
      ...propertyNameFilter,
      ...fieldTypeFilter,
      ...valueTypeFilter,
      ...defaultValueTypeFilter,
      ...stdOptionFilter,
      ...namespaceFilter,
    });
  };

  const handleActionsMenuClick = async (actionItemKey: string, actionPayload?: AttributeCols) => {
    switch (actionItemKey) {
      case 'edit':
        if (actionPayload) {
          const obj = {
            id: actionPayload?.id || '',
            locIds: locationIds,
            itemProps: actionPayload?.itemProps,
            isDelete: false,
            locationObject: isLocationView
              ? {
                  locationId: String(id) || '',
                  locationName: name || '',
                  value: String(actionPayload?.defaultValue) || '',
                }
              : {
                  locationId:
                    actionPayload?.itemProps?.fieldProperties?.values?.defaultValues?.[0]
                      ?.locationId || '',
                  locationName:
                    actionPayload?.itemProps?.fieldProperties?.values?.defaultValues?.[0]
                      ?.locationName || '',
                  value:
                    actionPayload?.itemProps?.fieldProperties?.values?.defaultValues?.[0]?.value ||
                    '',
                },
          };
          onEditAttribute(obj);
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      <GTable<AttributeCols>
        columns={columns({
          t,
          fieldSearchfilter,
          propertyNameSearchfilter,
          fieldTypeSearchfilter,
          valuesSearchfilter,
          defaultValueSearchfilter,
          handleActionsMenuClick,
          stdFilters,
          namespaceFilters,
          hasAdvancedSettings,
        })}
        // @ts-ignore
        onTableChange={onTableChange}
        value={records}
        rowKey="key"
        loading={isLoading}
        options={{
          setting: true,
          reload: () => refetch(),
        }}
        scroll={{ y: '200px', x: 2000 }}
        pagination={{
          defaultPageSize: param?.pageSize,
          total: dataTemplateAttributes?.totalItems,
          showTotal: (total, range) =>
            t('attribute_table_pagi', { range0: range[0], range1: range[1], total }),
        }}
      />
      {/* Outlet used to render the drawers */}
      <Outlet />
    </>
  );
};

export default React.memo(AttributeTable);
