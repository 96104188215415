import { message, Modal, Space, Typography } from 'antd';
import { Key } from 'antd/lib/table/interface';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  changeArchiveMultipleProductsAsync,
  getCachedProductById,
  useChangeArchiveProductById,
} from 'services/api';
import { errorHandler } from 'utils';
import { ProductItem } from './typings';

/**
 * Custom hook to handle product actions such as archiving and unarchiving.
 */
const useProductActions = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'products' });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const changeArchiveProduct = useChangeArchiveProductById(queryClient);

  const setModalContent = useCallback(
    (dataProduct: ProductItem, isTitle: boolean) => {
      if (dataProduct?.name) {
        if ((dataProduct?.currentInventory || 0) > 0) {
          if (isTitle) return t?.('archive_modal_title');
          return t?.('archive_modal_description', {
            name: dataProduct?.name || '',
          });
        }
        if ((dataProduct?.pendingShipmentItems || 0) > 0) {
          if (isTitle) return t?.('archive_modal_title_pending_shipment_error');
          return t?.('archive_modal_description_pending_shipment_error', {
            name: dataProduct?.name || '',
          });
        }
      }
      if (isTitle) return t?.('archive_bulk_modal_title');
      return t?.('archive_bulk_modal_description');
    },
    [t],
  );

  const activeInventoryModal = useCallback(
    (dataProduct: ProductItem) => {
      Modal.info({
        title: setModalContent(dataProduct, true),
        content: setModalContent(dataProduct, false),
        cancelText: t('archive_cancel_btn_text'),
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        okButtonProps: {
          hidden: true,
        },
        centered: true,
        okCancel: true,
        // have the highest z-index
        zIndex: 9999,
      });
    },
    [t, setModalContent],
  );

  const onViewArchived = useCallback(() => {
    navigate({
      pathname: `/settings`,
      search: `?tab=archived&section=products`,
    });
  }, [navigate]);

  const onViewUnarchived = useCallback(() => {
    navigate({
      pathname: `/products`,
    });
  }, [navigate]);

  const archiveConsent = useCallback(
    (productName?: string) =>
      new Promise((resolve) => {
        Modal.warning({
          title: t('archive_product_consent.title'),
          content: t('archive_product_consent.content', { product: productName }),
          centered: true,
          okCancel: true,
          cancelButtonProps: {
            type: 'primary',
            ghost: true,
            shape: 'round',
          },
          okText: t('archive_product_consent.archive'),
          cancelText: t('archive_product_consent.cancel'),
          okButtonProps: {
            type: 'primary',
            shape: 'round',
          },
          onCancel: () => resolve(false),
          onOk: () => resolve(true),
        });
      }),
    [t],
  );

  const onArchiveProduct = useCallback(
    async (actionPayload?: ProductItem) => {
      const name = actionPayload?.name || 'Product';
      if (
        actionPayload &&
        ((actionPayload?.currentInventory || 0) > 0 ||
          (actionPayload?.pendingShipmentItems || 0) > 0)
      ) {
        activeInventoryModal(actionPayload);
        return;
      }
      const consent = await archiveConsent(name);
      if (!consent) {
        return;
      }

      const success = message.loading(t('archiving_single_product'), 0);
      try {
        await changeArchiveProduct.mutateAsync({
          productId: actionPayload?.id || '',
          body: {
            archived: true,
          },
        });
        message.success(
          <Space>
            {t('archive_success', { name })}
            <Typography.Link
              onClick={(e) => {
                e.preventDefault();
                onViewArchived();
              }}
            >
              {t('view_archived')}
            </Typography.Link>
          </Space>,
        );
        success();
      } catch (error) {
        success();
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [t, archiveConsent, activeInventoryModal, onViewArchived, changeArchiveProduct],
  );

  const onUnarchiveProduct = useCallback(
    async (actionPayload?: ProductItem) => {
      const name = actionPayload?.name || 'Product';
      if (
        actionPayload &&
        ((actionPayload?.currentInventory || 0) > 0 ||
          (actionPayload?.pendingShipmentItems || 0) > 0)
      ) {
        activeInventoryModal(actionPayload);
      } else {
        const success = message.loading(t('unarchiving_single_product'), 0);
        try {
          await changeArchiveProduct.mutateAsync({
            productId: actionPayload?.id || '',
            body: {
              archived: false,
            },
          });
          message.success(
            <Space>
              {t('unarchive_success', { name })}
              <Typography.Link
                onClick={(e) => {
                  e.preventDefault();
                  onViewUnarchived();
                }}
              >
                {t('view')}
              </Typography.Link>
            </Space>,
          );
          success();
        } catch (error) {
          success();
          if (errorHandler(error)) {
            message.error(errorHandler(error));
          }
        }
      }
    },
    [activeInventoryModal, changeArchiveProduct, t, onViewUnarchived],
  );

  const onArchiveProductMultiple = useCallback(
    async (rowKeys?: Array<Key>) => {
      message.loading(t('archiving_multi_product'), 1);
      try {
        if (rowKeys?.length) {
          const productPayload: ProductItem = {} as ProductItem;
          let total = 0;
          const archivedProducts: string[] = [];

          const promises = rowKeys.reduce(
            (p, id) =>
              p.then(async () => {
                const product = await getCachedProductById(String(id));
                if (
                  (product?.currentInventoryTotal || 0) > 0 ||
                  (product?.pendingShipmentItems || 0) > 0
                ) {
                  productPayload.name = product?.name;
                  productPayload.pendingShipmentItems = product?.pendingShipmentItems;
                  productPayload.currentInventory = product?.currentInventory;
                } else {
                  archivedProducts.push(String(id));
                }
              }),
            Promise.resolve(),
          );
          await promises;
          await changeArchiveMultipleProductsAsync(
            archivedProducts?.map((id) => ({ id, archive: true })),
          );
          total = archivedProducts.length;
          const rowKeysLength = (rowKeys?.length ?? 0) - total;
          if (productPayload && rowKeysLength >= 1) {
            activeInventoryModal(rowKeysLength === 1 ? productPayload : ({} as ProductItem));
          }
          if (total) {
            message.success(
              <Space>
                {t('archive_success_many', { count: total || 0 })}
                <Typography.Link
                  onClick={(e) => {
                    e.preventDefault();
                    onViewArchived();
                  }}
                >
                  {t('view_archived')}
                </Typography.Link>
              </Space>,
            );
          }
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [t, activeInventoryModal, onViewArchived],
  );

  const onUnarchiveProductMultiple = useCallback(
    async (rowKeys?: Array<Key>) => {
      message.loading(t('unarchiving_product'), 1);
      try {
        if (rowKeys?.length) {
          await changeArchiveMultipleProductsAsync(
            rowKeys?.map((id) => ({ id: String(id), archive: false })),
          );

          message.success(
            <Space>
              {t('unarchive_success_many', { count: rowKeys?.length || 0 })}
              <Typography.Link
                onClick={(e) => {
                  e.preventDefault();
                  onViewUnarchived();
                }}
              >
                {t('view')}
              </Typography.Link>
            </Space>,
          );
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [t, onViewUnarchived],
  );

  return {
    onArchiveProduct,
    onArchiveProductMultiple,
    onUnarchiveProduct,
    onUnarchiveProductMultiple,
    loading: changeArchiveProduct?.isLoading,
  };
};
export default useProductActions;
