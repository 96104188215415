import { Button, Segmented, Space, Tabs } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { TitleBar } from 'components';
import { useShipmentStore } from 'pages/Shipments';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createSearchParams,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useShipmentMapShipments } from 'services/api/newShipments';
import { dateLocaleFormat, getNodeLifeCycleTime, getShipmentType } from 'utils';
import { getInboundSettingsTabSession } from '../InboundShipments/InboundTabSettings';
import ShipEventDiagram from './ShipEventDiagram';
import EventList from './ShipEventList';
import EventMap from './ShipEventMap';
import { EventTabs, EventTitleProps } from './typings';

const EventsTitle: FC<EventTitleProps> = ({ t }) => {
  const sections = useMemo(() => [t('details'), t('documents')], [t]);
  const [section, setSection] = useState<string | number>(sections[0]);
  const [queryParams] = useSearchParams();
  const name = queryParams?.get('name') || '1';
  const hideDocument = queryParams?.get('hideDocument') === 'true' || false;
  const navigate = useNavigate();
  const location = useLocation();
  const isOutbound = location.pathname?.includes('outbound');
  const isNetworkShip = location.pathname?.includes('network');
  const { shipmentId = '1' } = useParams();
  const { selectedShipments } = useShipmentStore();

  const onSegmentedButtonClick = (value: SegmentedValue) => {
    setSection(value);
    switch (value) {
      case t('details'):
        break;
      case t('documents'):
        navigate({
          pathname: '../documents',
          search: `?name=${name || '1'}`,
        });
        break;

      default:
        break;
    }
  };
  const onBack = () => {
    navigate(-1);
  };

  const onReceive = useCallback(() => {
    const sessionTab: string = getInboundSettingsTabSession();
    const record = selectedShipments?.[0] || {};
    const productsSearchParams = createSearchParams({
      shipmentId: record?.shipmentId || '',
      shipmentType: getShipmentType(sessionTab) || '',
      tradePartnerUrn: record?.destinationCompanyUrn || '',
      locationUrn: record?.destinationLocationUrn || '',
      eventDate: dateLocaleFormat(record?.eventDate || new Date()) || '',
      isPreview: String(true),
    });
    navigate({
      pathname: `/products/${shipmentId}/events/receive`,
      search: record ? `?${productsSearchParams}` : undefined,
    });
  }, [navigate, selectedShipments, shipmentId]);

  return (
    <TitleBar
      className="page-header"
      title={
        <Space>
          {name || ''}
          {!hideDocument && (
            <Segmented
              value={section || sections[0]}
              options={sections}
              onChange={onSegmentedButtonClick}
            />
          )}
        </Space>
      }
      extra={
        !(isOutbound || isNetworkShip) ? (
          <Button type="primary" shape="round" onClick={onReceive}>
            Receive
          </Button>
        ) : null
      }
      onBack={onBack}
    />
  );
};
const EventDetails = () => {
  const [currentTab, setCurrentTab] = useState<EventTabs>('map');
  const { t } = useTranslation('pages', { keyPrefix: 'products.event_details' });
  const { shipmentId = '1' } = useParams();

  const { data: instances } = useShipmentMapShipments(shipmentId);

  const lifeCyle = useMemo(() => getNodeLifeCycleTime(instances?.nodes), [instances]);

  const onTabChange = (activeKey: EventTabs) => {
    setCurrentTab(activeKey);
  };
  return (
    <>
      <EventsTitle tab={currentTab} instances={instances} t={t} />
      <Tabs defaultActiveKey="map" onChange={(key) => onTabChange(key as EventTabs)}>
        <Tabs.TabPane key="map" tab={t('map')}>
          {currentTab === 'map' && <EventMap t={t} lifeCyle={lifeCyle} />}
        </Tabs.TabPane>
        <Tabs.TabPane key="diagram" tab={t('diagram')}>
          {currentTab === 'diagram' && <ShipEventDiagram t={t} lifeCyle={lifeCyle} />}
        </Tabs.TabPane>
        <Tabs.TabPane key="list" tab={t('list')}>
          {currentTab === 'list' && <EventList />}
        </Tabs.TabPane>
      </Tabs>
      <Outlet />
    </>
  );
};
export default React.memo(EventDetails);
