import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-table';
import { Modal, Tag, Tooltip, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import classNames from 'classnames';
import { Actions, ContactUsModal, GMap, TitleBar } from 'components';
import { MarkerItemProps } from 'components/GMap/typings';
import GTable from 'components/GTable';
import useListLocation from 'hooks/useListLocation';
import useModalVisibility from 'hooks/useModalVisibility';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import { renderInfoWindow } from 'pages/Network/hook';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ListNetworksOptionalParams } from 'services/api/client/src';
import { useNetworks } from 'services/api/useLocations';
import useTradePartners from 'services/api/useTradePartners';
import { getStatus, getStatusColor, isNetworkInvite } from 'utils/network';
import { EmptyState } from './components';
import { useCompanySelectStore } from './components/CompanySelectInput';
import { Invite, ShareLocation } from './forms';
import UpgradeModal from './forms/AddEdit/UpgradeModal';
import useDeleteActions from './hook/useDeleteActions';
import useSubscriptionAction from './hook/useSubscriptionActions';
import styles from './index.module.less';
import { FilterArrayProp, NetworkItem, Pagination, ReactText, TitleProp } from './typings';

const MainActionItems = (): ItemType[] => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.network_actions' });
  return [
    {
      key: 'add_company',
      label: t('add_company'),
      disabled: false,
    },
    {
      key: 'invite',
      label: t('invite'),
      disabled: false,
    },
  ];
};

const NetworksTitle = ({
  count,
  inviteModal,
  upgradeModal,
  allowAddLocation,
  allowInvite,
  t,
}: TitleProp) => {
  const setSelectedCompany = useCompanySelectStore((state) => state.setSelectedCompany);
  const setCompanyEmpty = useCompanySelectStore((state) => state.setCompanyEmpty);

  const navigate = useNavigate();
  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'add_company':
        if (allowAddLocation) {
          setSelectedCompany();
          setCompanyEmpty();
          navigate('add_location');
        } else {
          upgradeModal?.show();
        }

        break;
      case 'invite':
        if (allowInvite) {
          inviteModal.show();
        } else {
          upgradeModal?.show();
        }

        break;

      default:
        break;
    }
  };

  return (
    <TitleBar
      title={<Typography.Title level={4}>{t?.('title', { count })}</Typography.Title>}
      actionItems={MainActionItems()}
      onActionItemClick={onActionItemClick}
    />
  );
};
const Network = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('pages', { keyPrefix: 'network' });
  const [param, setParam] = useState<ListNetworksOptionalParams>({
    pageNumber: 1,
    pageSize: 10,
  });
  const { data: locations = {}, isLoading, isFetching, refetch } = useNetworks(param);
  const { data: allLocations = {}, isLoading: isAllLoading } = useNetworks({
    pageSize: 9999,
  });
  const { refetch: refetchLocation } = useListLocation();
  const { cancelInvite, resendInvite, loading: loadingDeleteAction } = useDeleteActions();
  const { data: coreCompany } = useTradePartners();
  const shareLocModal = useModalVisibility(false);
  const contactUsModal = useModalVisibility(false);
  const upgradeModal = useModalVisibility(false);
  const [locationToShare, setLocationToShare] = useState<NetworkItem>();
  const setSelectedCompany = useCompanySelectStore((state) => state.setSelectedCompany);
  const setCompanyEmpty = useCompanySelectStore((state) => state.setCompanyEmpty);

  const { allowInternalLocation, allowExternalLocation } = useSubscriptionAction();

  const inviteModal = useModalVisibility(false);
  const { filters: nameSearchfilter } = useTableSearchFilter({
    title: t('network_table_cols.location_name'),
  });
  const { filters: addressSearchfilter } = useTableSearchFilter({
    title: t('network_table_cols.location_address'),
  });

  const onNetworkInviteView = useCallback(
    (company: string) => {
      Modal.warning({
        title: t('invite.view_network_invite_title', { company }),
        content: t('invite.view_network_invite_content'),
        centered: true,
        okCancel: false,
        width: 500,
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
        okText: t('invite.invalid_wc_done'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
      });
    },
    [t],
  );

  const actionItems = (record: NetworkItem): ItemType[] => [
    {
      key: 'viewCompany',
      label: t('location_actions.view_company'),
      disabled: false,
    },
    {
      key: 'edit',
      label: t('location_actions.edit'),
      disabled: false,
    },
    ...(record.isOwnAccount
      ? [
          {
            key: 'share',
            label: t('location_actions.share'),
            disabled: false,
          },
        ]
      : []),
  ];

  const inviteRequestActionItems: ItemType[] = [
    {
      key: 'resendInvite',
      label: t('location_actions.resend_invite'),
      disabled: loadingDeleteAction || isFetching,
    },
    {
      key: 'deleteInvite',
      label: t('location_actions.delete_invite'),
      disabled: loadingDeleteAction || isFetching,
    },
  ];

  const handleActionsMenuClick = (actionItemKey: string, actionPayload?: NetworkItem) => {
    switch (actionItemKey) {
      case 'viewCompany':
        navigate(`${actionPayload?.companyId}`);
        break;

      case 'edit':
        navigate(`${actionPayload?.companyId}/editLocation`);
        break;

      case 'share':
        if (actionPayload) {
          setLocationToShare(actionPayload);
          shareLocModal.show();
        }
        break;

      case 'deleteInvite':
        cancelInvite(String(actionPayload?.id) || '', actionPayload?.company);
        break;

      case 'resendInvite':
        resendInvite(actionPayload);
        break;

      default:
        break;
    }
  };

  const records: Array<NetworkItem> = useMemo(
    () =>
      locations?.results?.map((item) => ({
        id: item?.invite?.id || item?.location?.id || '',
        name: item?.location?.name || '-',
        company: item?.tradePartner?.name || '-',
        locationAddress: !item?.invite?.id
          ? item?.location?.address?.displayAddress ||
            `${item?.location?.coordinates?.latitude},${item?.location?.coordinates?.longitude}`
          : '-',
        lat: Number(item?.location?.coordinates?.latitude || 91),
        lng: Number(item?.location?.coordinates?.longitude || 181),
        companyId: item?.tradePartner?.id,
        isOwnAccount: item?.tradePartner?.status === 'Self',
        ownerHasWholechainAccount: item?.tradePartner?.status === 'Wholechain',
        showLocTooltip:
          !item?.invite?.id &&
          item?.tradePartner?.status === 'Wholechain' &&
          !item?.location?.isShared,
        status: getStatus(
          item?.invite?.id ? item?.invite?.status || '' : item?.tradePartner?.status || '',
        ),
        wholechainId: item?.invite?.wholechainId,
      })) || [],
    [locations?.results],
  );

  const markers = useMemo<Array<MarkerItemProps>>(
    () =>
      allLocations?.results?.map((item) => ({
        id: item?.invite?.id || item?.location?.id || '',
        position: {
          lat: Number(item?.location?.coordinates?.latitude || 91),
          lng: Number(item?.location?.coordinates?.longitude || 181),
        },
        title: item?.tradePartner?.name || '-',
        companyId: item?.tradePartner?.id,
        shortDesc: item?.location?.name || '-',
        longDesc: !item?.invite?.id
          ? item?.location?.address?.displayAddress ||
            `${item?.location?.coordinates?.latitude},${item?.location?.coordinates?.longitude}`
          : '-',
        isMyLocation: item?.tradePartner?.status === 'Self',
      })) || [],
    [allLocations?.results],
  );

  const coreCompanyFilter: Array<FilterArrayProp> = useMemo(
    () =>
      coreCompany?.data?.map((item) => ({
        text: item.name || '',
        value: item.id || '',
      })) || [],
    [coreCompany],
  );

  const statusFilter = [
    { text: t('status.wholechain'), value: 'Wholechain' },
    { text: t('status.nonwholechain'), value: 'NonWholechain' },
    { text: t('status.pending'), value: 'Pending' },
    { text: t('status.rejected'), value: 'Rejected' },
  ];

  const columns: ProColumns<NetworkItem>[] = [
    {
      title: t('network_table_cols.company'),
      dataIndex: 'company',
      filters: coreCompanyFilter,
      filterSearch: true,
      hideInSetting: true,
      render: (text, record) => (
        <Link
          to={String(record.id)}
          title={`${t('view')} ${text}`}
          className="text-link"
          onClick={(e) => {
            e.preventDefault();
            if (isNetworkInvite(record?.status || '')) {
              onNetworkInviteView(record?.company || '');
            } else {
              navigate(`${record?.companyId}`);
            }
          }}
        >
          <div>
            {`${text}`}
            <span>
              <Typography.Text type="secondary">
                {record.isOwnAccount ? ` ${t('me')}` : ''}
              </Typography.Text>
            </span>
          </div>
        </Link>
      ),
    },
    {
      title: t('network_table_cols.location_address'),
      dataIndex: 'locationAddress',
      ellipsis: true,
      ...addressSearchfilter,
    },
    {
      title: t('network_table_cols.location_name'),
      dataIndex: 'name',
      ellipsis: true,
      ...nameSearchfilter,
      render: (text, record) =>
        record?.name !== '-' ? (
          <Link
            className="text-link"
            to={String(`${record?.companyId}/${record?.id}`)}
            title={`${t('view')} ${record?.name}`}
          >
            <div>
              {`${record?.name}`}
              <span>
                {record?.showLocTooltip && (
                  <Tooltip title={t('location_name_tooltip')}>
                    <ExclamationCircleOutlined className={styles.locationtooltip} />
                  </Tooltip>
                )}
              </span>
            </div>
          </Link>
        ) : (
          '-'
        ),
    },
    {
      title: t('network_table_cols.status'),
      dataIndex: 'status',
      filters: statusFilter,
      render: (text, record) => (
        <Tag className={getStatusColor(record?.status || '')}>{`${t(
          `status.${String(text).toLowerCase().replace('-', '')}`,
        )}`}</Tag>
      ),
    },
    {
      dataIndex: 'actions',
      hideInSetting: true,
      render: (text, record) => (
        <Actions
          className="actions"
          buttonProps={{
            ghost: true,
            size: 'small',
          }}
          items={
            isNetworkInvite(record?.status || '') ? inviteRequestActionItems : actionItems(record)
          }
          onClick={handleActionsMenuClick}
          actionPayload={record}
        />
      ),
      width: '10%',
    },
  ];

  const filterTable = useCallback(
    (params: Pagination, sorter: any, filter: Record<string, ReactText[] | null>) => {
      const compFilter = filter?.company
        ? {
            tradePartnerIds: (filter?.company as string[]) || [],
          }
        : {};
      const nameFilter = filter?.name
        ? {
            locationName: filter?.name?.[0] as string,
          }
        : {};
      const addressFilter = filter?.locationAddress
        ? {
            address: filter?.locationAddress?.[0] as string,
          }
        : {};

      const tradePartnerFilter = filter?.status
        ? {
            status: filter?.status as string[],
          }
        : {};

      setParam({
        pageNumber: params?.current || 1,
        pageSize: params?.pageSize || 10,
        ...compFilter,
        ...nameFilter,
        ...addressFilter,
        ...tradePartnerFilter,
      });
      return Promise.resolve({
        data: records,
        success: true,
      });
    },
    [records],
  );

  const isEmpty = useMemo(
    () => !isAllLoading && allLocations?.totalItems === 0,
    [isAllLoading, allLocations?.totalItems],
  );

  const onAddLocation = useCallback(() => {
    if (allowExternalLocation || allowInternalLocation) {
      setSelectedCompany();
      setCompanyEmpty();
      navigate('add_location');
    } else {
      upgradeModal?.show();
    }
  }, [
    allowExternalLocation,
    allowInternalLocation,
    navigate,
    setCompanyEmpty,
    setSelectedCompany,
    upgradeModal,
  ]);

  return (
    <>
      {upgradeModal.visible && <UpgradeModal modal={upgradeModal} />}
      {inviteModal.visible && (
        <Invite modal={inviteModal} onContactUs={() => contactUsModal.show()} />
      )}
      {shareLocModal.visible && (
        <ShareLocation modal={shareLocModal} locationToShare={locationToShare} />
      )}
      <ContactUsModal contactUsModal={contactUsModal} />
      {isEmpty ? (
        <>
          <GMap />
          <NetworksTitle
            count={locations?.totalItems || 0}
            inviteModal={inviteModal}
            upgradeModal={upgradeModal}
            allowAddLocation={allowExternalLocation || allowInternalLocation}
            allowInvite={allowExternalLocation}
            t={t}
          />
          <EmptyState onAddLocation={onAddLocation} />
        </>
      ) : (
        <>
          <GMap markers={markers} renderInfoWindow={renderInfoWindow} isRestrict />
          <GTable<NetworkItem>
            className={classNames(styles.topmargin)}
            columns={columns}
            request={filterTable}
            headerTitle={
              <NetworksTitle
                count={locations?.totalItems || 0}
                inviteModal={inviteModal}
                upgradeModal={upgradeModal}
                allowAddLocation={allowExternalLocation || allowInternalLocation}
                allowInvite={allowExternalLocation}
                t={t}
              />
            }
            value={records}
            options={{
              reload: () => {
                refetch();
                refetchLocation();
              },
            }}
            loading={isLoading}
            scroll={{ y: 300, x: 800 }}
            pagination={{
              defaultPageSize: param?.pageSize,
              total: allLocations?.totalItems,
              showTotal: (total, range) =>
                t('network_table_pagi', { range0: range[0], range1: range[1], total }),
            }}
            columnsState={{
              persistenceKey: 'pages-networks',
            }}
          />
        </>
      )}
    </>
  );
};

export default React.memo(Network);
