import { ConfigProvider } from 'antd';
import { Locale } from 'antd/lib/locale-provider';
import enUS from 'antd/lib/locale/en_US';
import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TranslationContextValue, TranslationProviderProps } from './typings';

const TranslationContext = createContext<TranslationContextValue>({
  locale: enUS,
  setLocale: () => {},
  isTLoading: false,
});
const TranslationProvider: FC<TranslationProviderProps> = ({ children }) => {
  const [locale, setLocale] = useState<Locale>(enUS);

  const [isTLoading, setTLoading] = useState<boolean>(false);

  const onChangeLanguage = useCallback(() => {
    if (locale) {
      setTLoading(true);
      setTimeout(() => {
        setTLoading(false);
      }, 1500);
    }
  }, [locale]);

  useEffect(() => onChangeLanguage, [onChangeLanguage]);

  const contextValue = useMemo(
    () => ({
      locale,
      setLocale,
      isTLoading,
    }),
    [setLocale, locale, isTLoading],
  );
  return (
    <TranslationContext.Provider value={contextValue}>
      <ConfigProvider locale={locale}>{children}</ConfigProvider>
    </TranslationContext.Provider>
  );
};

export const useTranslationContext = (): TranslationContextValue => {
  const context = useContext(TranslationContext);

  if (context === undefined) {
    throw new Error('TranslationContext must be used within a TranslationContextProvider');
  }

  return context;
};
export default TranslationProvider;
