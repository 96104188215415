import { ProColumns } from '@ant-design/pro-table';
import { GraphData } from '@antv/g6/lib/types';
import { RcFile } from 'antd/lib/upload';
import { EventTableItem } from 'components/Settings/DefinedAttributes/components/EventTable/EventTable.fields';
import { queryClient } from 'index';
import moment from 'moment';
import { CertificationInfo, CommissionDataItem } from 'pages/Events';
import { CustomAttribute, TemplateDataItem, TemplateItem } from 'pages/Templates';
import {
  commissionTemplate,
  observeTemplate,
  receiveTemplate,
  shipTemplate,
  transformTemplate,
} from 'pages/Workflows';
import {
  AccountResponse,
  AttributeResponse,
  EventTemplatesResponse,
  ListAttributesResponse,
  SubscriptionResponse,
  TemplateAttributeResponse,
  TemplateCertificationRequest,
  TemplateCertificationResponse,
  UpdateTemplateAttributeRequest,
} from 'services/api/client/src';
import { getProductsT, getTParam } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';
import { capitalizeFirstLetter, dateLocalFormat, fieldTypes, keysToSampleObject } from './helpers';
import { certificationFields, certificationMappings, vesselCatchFields } from './products';
import {
  CSVTemplate,
  GetCSVTemplateFields,
  GetStringifiedValFn,
  MapAdvanceSettingsFn,
  MapCertRequestFn,
  MapCustomAtrributeFn,
  MapTemplateItemFn,
  SheetResponse,
} from './typings';

const getTemplate = (eventType: string): CSVTemplate => {
  const type = eventType?.trim();
  switch (type) {
    case 'Commission':
      return commissionTemplate;
    case 'Decommission':
      return commissionTemplate;
    case 'Observe':
      return observeTemplate;
    case 'Aggregate':
      return commissionTemplate;
    case 'Disaggregate':
      return commissionTemplate;
    case 'Transform':
      return transformTemplate;
    case 'Ship':
      return shipTemplate;
    case 'Receive':
      return receiveTemplate;
    default:
      return commissionTemplate;
  }
};
export const downloadWorkflowExcelTemplate = (nodes: GraphData['nodes']) => {
  const workbook = XLSX.utils.book_new();
  /* create a sheet in excel with the name of the event */
  nodes?.forEach((event, idx) => {
    /* index added with sheet name to prevent same name */
    const eventTemplate = getTemplate(String(`${event?.eventType || 'Commission'}`));

    const sampleObject = keysToSampleObject(eventTemplate);
    const worksheet = XLSX.utils.json_to_sheet([sampleObject]);
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      String(`${idx + 1}.${event?.eventType || 'Commission'}`),
    );
  });
  /* write the workbook to a file */
  XLSX.writeFile(workbook, `Workflow.xlsx`);
};

export const readWorkflowExcelTemplate = (file: RcFile): Promise<SheetResponse> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      /* output json object containing key as sheet name and value as array of objects */
      const output: SheetResponse = {};
      workbook.SheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        output[String(sheetName)] = XLSX.utils.sheet_to_json(worksheet) as Array<CSVTemplate>;
      });
      resolve(output as SheetResponse);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsBinaryString(file);
  });

/* strings to be considered null if value "null" or starts with 0001 or starts with 00000000 */
export const isNullString = (value?: string | Date) => {
  const isDate = value instanceof Date;
  if (isDate) {
    return moment(value).format('YYYY-MM-DD') === '0001-01-01';
  }
  return (
    value === 'null' ||
    value === undefined ||
    value?.startsWith('0001') ||
    value?.startsWith('00000000') ||
    false
  );
};

export const mockSubscription: SubscriptionResponse = {
  name: 'ENTERPRISE4',
  description: '',
  monthlyRate: 0,
  totalProducts: 1,
  shipmentType: 'ALL',
  annualShipments: 2147483647,
  receiveSupplierShipments: true,
  partnerEvents: true,
  totalCompanyLocation: 2147483647,
  totalOutsideLocation: 2147483647,
  apiAccess: true,
  customMSA: true,
  integrations: true,
  sourceviewAddon: false,
  monthlySharedDocuments: 2147483647,
  eventLimitation: 2147483647,
  totalUsers: 0,
  // @ts-ignore
  totalDocuments: 12,
  // @ts-ignore
  totalLocationInstances: 2147483647,
  templatesType: 'ALL',
  templates: 9,
  workflowsType: 'ALL',
  workflows: 2147483647,
  createdBy: '00000000-0000-0000-0000-000000000000',
  lastModifiedBy: '00000000-0000-0000-0000-000000000000',
  accountId: '00000000-0000-0000-0000-000000000000',
  deleted: false,
  id: '35b7a590-4dd8-4bb9-8b56-3133ef4bb0f9',
};
export const getVesselFields = () => {
  const vesselCatchKeys: {
    [key: string]: string;
  } = {
    vesselName: getProductsT('vessel_name'),
    vesselFlagState: getProductsT('vessel_flag_state'),
    imoNumber: getProductsT('imo_number'),
    vesselPublicRegistry: getProductsT('vessel_public_registry'),
    gpsAvailability: getProductsT('gps_availability'),
    satelliteTrackingAuthority: getProductsT('satellite_tracking_authority'),
    economicZone: getProductsT('economic_zone'),
    fisheryImprovementProject: getProductsT('fishery_improvement_project'),
    rfmoArea: getProductsT('rfmo_area'),
    subnationalPermitArea: getProductsT('subnational_permit_area'),
    catchArea: getProductsT('catch_area'),
    fishingGearTypeCode: getProductsT('fishing_gear_type_code'),
    vesselTripDate: getProductsT('vessel_trip_date'),
    vesselTripDateStart: getProductsT('vessel_trip_date_start'),
    vesselTripDateEnd: getProductsT('vessel_trip_date_end'),
  };

  const attributeCache = queryClient.getQueryData<ListAttributesResponse | undefined>([
    'attributes',
    undefined,
  ]);

  const vesselFields = Object.keys(vesselCatchFields)?.map((key) => {
    /* retrieve vessel field property from attribute if present */

    const vesselField = attributeCache?.results?.find(
      (el) => el?.fieldProperties?.fields?.propertyName === key,
    );
    const attRes: TemplateAttributeResponse = {
      attribute: {
        name: vesselCatchKeys[key],
        fieldProperties: {
          fields: {
            propertyName: key,
            fieldType: capitalizeFirstLetter(vesselCatchFields[key]?.valueType || 'text'),
            required: vesselCatchFields[key]?.required || false,
          },
          values: {
            valueOptions: [],
            defaultValue: undefined,
          },
          namespace: {
            name: vesselField?.fieldProperties?.namespace?.name || 'custom',
            prefix: vesselField?.fieldProperties?.namespace?.prefix || 'wc',
            uri: vesselField?.fieldProperties?.namespace?.uri || 'wholechain.com',
          },
          location: vesselField?.fieldProperties?.location,
        },
      },
    };

    return attRes;
  });
  return vesselFields;
};
export const certKeys = [
  'certificationType',
  'certificationStandard',
  'certificationAgency',
  'certificationValue',
  'certificationIdentification',
];

export const getCertFields = () => {
  const certificationKeys: {
    [key: string]: string;
  } = {
    certificationType: getProductsT('cert_type'),
    certificationStandard: getProductsT('cert_standard'),
    certificationAgency: getProductsT('cert_agency'),
    certificationValue: getProductsT('cert_value'),
    certificationIdentification: getProductsT('cert_id'),
  };
  const certFields = Object.keys(certificationFields)?.map((key) => ({
    name: certificationKeys[key],
    propertyName: key,
    fieldType: capitalizeFirstLetter(certificationFields[key]?.valueType || 'text'),
    required: certificationFields[key]?.required || false,
    valueOptions: [],
    defaultValue: undefined,
    namespace: {
      name: 'custom',
      prefix: 'wc',
      uri: 'wholechain.com',
    },
  }));

  return certFields;
};

export const getAddittionOptionsAttributes = (formData: TemplateDataItem) => {
  const isVesselCatch = formData?.additionalOptions?.includes('vesselCatch');
  const isCertification = formData?.additionalOptions?.includes('certificationDocuments');

  const vesselFields = getVesselFields();

  const certFields = getCertFields();

  const additionalOptions = [
    ...(isVesselCatch ? vesselFields : []),
    ...(isCertification ? certFields : []),
  ];

  return additionalOptions;
};
export const mapTFieldtoCustomAttribute = (el: TemplateAttributeResponse): CustomAttribute => ({
  id: el?.attribute?.id || el?.attribute?.fieldProperties?.fields?.propertyName || '0',
  name: el?.attribute?.name,
  attributeId: el?.attributeId,
  required: el?.attribute?.fieldProperties?.fields?.required,
  dataAttribute: el?.attribute?.fieldProperties?.fields?.propertyName,
  fieldType: el?.attribute?.fieldProperties?.fields?.fieldType || 'Text',
  valueOptions: el?.attribute?.fieldProperties?.values?.valueOptions,
  defaultValue: el?.defaultValue,
  hidden: el?.attribute?.fieldProperties?.fields?.hidden,
  location: el?.attribute?.fieldProperties?.location,
  namespace:
    el?.attribute?.fieldProperties?.namespace?.name === 'custom'
      ? undefined
      : el?.attribute?.fieldProperties?.namespace?.name,
});

export const mapTFieldtoCustomAttributeTemplateField = (
  el: TemplateAttributeResponse,
): CustomAttribute => {
  const attribute = el?.attribute;
  const fieldProperties = attribute?.fieldProperties;
  const fields = fieldProperties?.fields;
  const values = fieldProperties?.values;
  const propertyName = fields?.propertyName;
  return {
    id: attribute?.id || propertyName || '0',
    name: attribute?.name,
    attributeId: attribute?.id,
    required: fields?.required,
    dataAttribute: propertyName,
    fieldType: fields?.fieldType || 'Text',
    valueOptions: values?.valueOptions,
    defaultValue: el?.defaultValue,
    hidden: fields?.hidden,
    location: fieldProperties?.location,
    namespace:
      fieldProperties?.namespace?.name === 'custom' ? undefined : fieldProperties?.namespace?.name,
  };
};
export const mapCustomAtrribute: MapCustomAtrributeFn = (attr) => {
  const request: UpdateTemplateAttributeRequest = {
    id: attr?.attributeId,
    name: attr?.name || '',
    fieldProperties: {
      fields: {
        propertyName: attr.dataAttribute,
        fieldType: attr.fieldType,
        required: attr.required,
        hidden: attr?.hidden,
      },
      values: {
        valueOptions: attr.valueOptions,
        defaultValue: attr.defaultValue,
      },
      location: attr?.location,
      namespace: {
        name: attr?.namespace || 'custom',
        prefix: 'wc',
        uri: 'wholechain.com',
      },
    },
  };

  return request;
};
export const mapAdvanceSettings: MapAdvanceSettingsFn = (attr) => {
  const request: UpdateTemplateAttributeRequest = {
    // id: attr.attributeId,
    name: attr?.label || '',
    fieldProperties: {
      fields: {
        propertyName: attr?.id,
        fieldType: 'Dropdown',
        required: false,
      },
      values: {
        valueOptions: attr.value ? [attr.value] : [],
        defaultValue: attr.value,
      },
      namespace: {
        name: 'custom',
        prefix: 'wc',
        uri: 'wholechain.com',
      },
    },
  };

  return request;
};
export const mapCertInfoRequest: MapCertRequestFn = (c) => {
  const request: TemplateCertificationRequest = {
    id: String(c?.id)?.includes('newField') ? undefined : c?.id,
    type: c.certificationType,
    standard: c.certificationStandard,
    agency: c.certificationAgency,
    value: c.certificationValue,
    identification: c.certificationIdentification,
  };

  return request;
};

export const filterAttribute = (item: TemplateAttributeResponse) =>
  // filter propertyName from vesselCatchFields and certificationFields

  {
    const fieldProperties = item?.attribute?.fieldProperties;
    const propertyName = fieldProperties?.fields?.propertyName;
    const hidden = fieldProperties?.fields?.hidden;

    return propertyName !== 'bizStep' && propertyName !== 'disposition' && !hidden;
  };

export const filterTAttributes = (item: UpdateTemplateAttributeRequest) =>
  // filter propertyName from vesselCatchFields and certificationFields
  {
    const propertyName = item?.fieldProperties?.fields?.propertyName;

    return (
      propertyName !== 'bizStep' &&
      propertyName !== 'disposition' &&
      Object.keys(vesselCatchFields)?.includes(propertyName || '') === false &&
      Object.keys(certificationFields)?.includes(propertyName || '') === false
    );
  };

// filter out hidden attributes
export const filterHiddenAttributes = (item: TemplateAttributeResponse) =>
  item?.attribute?.fieldProperties?.fields?.hidden === false;

// filter out certificationFields and vessel fields that are hidden
export const filterTCertAttribute = (item: TemplateAttributeResponse) =>
  item?.attribute?.fieldProperties?.fields?.propertyName !== 'bizStep' &&
  item?.attribute?.fieldProperties?.fields?.propertyName !== 'disposition' &&
  !item?.attribute?.fieldProperties?.fields?.hidden &&
  Object.keys(certificationFields)?.includes(
    item?.attribute?.fieldProperties?.fields?.propertyName || '',
  ) === false;

/* filter propertyName from vesselCatchFields and certificationFields */
export const filterTDataAttributes = (item: CustomAttribute) =>
  item?.dataAttribute !== 'bizStep' &&
  item?.dataAttribute !== 'disposition' &&
  Object.keys(vesselCatchFields)?.includes(item?.dataAttribute || '') === false &&
  Object.keys(certificationFields)?.includes(item?.dataAttribute || '') === false;

/* filter only vesselCatchAttributes */
export const filterOnlyVesselCatchAttributes = (item: CustomAttribute) =>
  Object.keys(vesselCatchFields)?.includes(item?.dataAttribute || '') === true &&
  Object.keys(certificationFields)?.includes(item?.dataAttribute || '') === false;

/* filter only certificationAttributes */
export const filterOnlyCertificationAttributes = (item: CustomAttribute) =>
  Object.keys(certificationFields)?.includes(item?.dataAttribute || '') === true &&
  Object.keys(vesselCatchFields)?.includes(item?.dataAttribute || '') === false;

/* filter only certificationAttributes from EventTemplate */
export const filterOnlyCATemplate = (item: EventTemplatesResponse) => {
  const attributes = item?.templateAttributes;
  const fields = attributes?.filter((el) => {
    const propertyName = el?.attribute?.fieldProperties?.fields?.propertyName;
    return (
      Object.keys(certificationFields)?.includes(propertyName || '') === true &&
      Object.keys(vesselCatchFields)?.includes(propertyName || '') === false
    );
  });
  return fields;
};

/* throw error if property name belongs to vessel catch attributes and certification attributes */
export const includesVOrCert = (propertyName?: string) => {
  if (
    propertyName &&
    (Object.keys(vesselCatchFields)?.includes(propertyName) === true ||
      Object.keys(certificationFields)?.includes(propertyName) === true)
  ) {
    return true;
  }
  return false;
};
export const valPropertyName = (propertyName?: string) => {
  if (includesVOrCert(propertyName)) {
    throw new Error(getProductsT('invalid_property'));
  }
};

export const filterTemplateAtrributes =
  (records?: Array<CustomAttribute>) => (item?: AttributeResponse) => {
    const fieldProperties = item?.fieldProperties;
    const field = fieldProperties?.fields;
    /* Filter existing records */
    const existingRecord = records?.find((p) => p?.dataAttribute === field?.propertyName);
    /* Also filter vessel catch and certification fields */
    const vesselOrCertProperty = includesVOrCert(field?.propertyName);

    /* filter advanced setting fields */
    const isAdvancedField = item?.name === 'Disposition' || item?.name === 'Business Step';
    return !existingRecord && !vesselOrCertProperty && !isAdvancedField;
  };

export const templateAttributesCheckbox = (record: EventTableItem) => ({
  disabled: !record.allowDelete,
});

export const getCSVTemplateFields: GetCSVTemplateFields = ({
  templateFields,
  defaultValues,
  certFields,
}) => {
  const columnWidth = 100;
  const t = (str: string, param?: object) => getTParam(`events.csv.${str}`, param);

  const tFields: Array<ProColumns<CommissionDataItem>> =
    templateFields?.map((item) => ({
      title: item?.attribute?.name || '',
      dataIndex: item?.attribute?.fieldProperties?.fields?.propertyName || '',
      valueType:
        fieldTypes[item?.attribute?.fieldProperties?.fields?.fieldType || 'Text'].valueType,
      initialValue: defaultValues?.[item?.attribute?.fieldProperties?.fields?.propertyName || ''],
      fieldProps: {
        ...fieldTypes[item?.attribute?.fieldProperties?.fields?.fieldType || 'Text'].fieldProps,
        placeholder: item?.attribute?.name || '',
        size: 'small',
        options: item?.attribute?.fieldProperties?.values?.valueOptions,
      },
      formItemProps: {
        rules: [
          {
            required: item?.attribute?.fieldProperties?.fields?.required || false,
            validator: (_: any, value: string) => {
              if (item?.attribute?.fieldProperties?.fields?.required) {
                /* check input is filled */
                if (
                  item?.attribute?.fieldProperties?.fields?.fieldType !== 'Number' &&
                  !value?.trim()
                ) {
                  return Promise.reject(
                    new Error(
                      t?.('template_field_req', {
                        field: item?.attribute?.name || '',
                      }),
                    ),
                  );
                }
                if (item?.attribute?.fieldProperties?.fields?.fieldType === 'Number' && !value) {
                  return Promise.reject(
                    new Error(
                      t?.('template_field_req', {
                        field: item?.attribute?.name || '',
                      }),
                    ),
                  );
                }
                /* input is filled, now check if the values are correct */
                if (
                  (item?.attribute?.fieldProperties?.fields?.fieldType === 'Dropdown' ||
                    item?.attribute?.fieldProperties?.fields?.fieldType === 'RadioButton') &&
                  !item?.attribute?.fieldProperties?.values?.valueOptions?.includes(value) &&
                  value?.trim()
                ) {
                  return Promise.reject(new Error(t?.('template_field_valid')));
                }
                return Promise.resolve();
              }

              /* input is filled,not required, now check if the values are correct */
              if (
                (item?.attribute?.fieldProperties?.fields?.fieldType === 'Dropdown' ||
                  item?.attribute?.fieldProperties?.fields?.fieldType === 'RadioButton') &&
                !item?.attribute?.fieldProperties?.values?.valueOptions?.includes(value) &&
                value?.trim()
              ) {
                return Promise.reject(new Error(t?.('template_field_valid')));
              }

              return Promise.resolve();
            },
          },
        ],
      },
      ellipsis: true,
      width:
        item?.attribute?.fieldProperties?.fields?.fieldType !== 'RadioButton'
          ? columnWidth
          : 2 * Number(columnWidth),
    })) || [];

  const cFields =
    certFields?.reduce((acc, item, idx) => {
      Object.keys(item)
        ?.filter((key) => key !== 'id' && key !== 'eventTemplateId', [])
        ?.forEach((key) => {
          const title = `${getProductsT(certificationMappings[key])} ${idx + 1}`;
          const dataIndex = `${key} ${idx + 1}`;

          acc.push({
            title,
            dataIndex,
            fieldProps: {
              size: 'small',
              placeholder: title,
            },
            ellipsis: true,
            width: Number(columnWidth),
          });
        });
      return acc;
    }, [] as Array<ProColumns<CommissionDataItem>>) || [];
  const fields = [...tFields, ...cFields];
  return fields;
};

export const getStringifiedVal: GetStringifiedValFn = (value) => {
  const valueType = typeof value;
  const isString = valueType === 'string';
  const isDate = valueType === 'object' && value instanceof Date;
  const isNullorUndefined = value === null || value === undefined;
  let newValue: string;
  if (isNullorUndefined) {
    newValue = '';
  } else if (isString || isDate) {
    newValue = value as string;
  } else {
    newValue = JSON.stringify(value);
  }
  return newValue;
};

export const mapTemplateItem: MapTemplateItemFn = (t) => {
  const title = t?.templateName || '';
  const account = queryClient.getQueryData<AccountResponse | undefined>(['account', undefined]);
  const role = t?.sharedWith?.find((i) => i?.accountId === account?.id, [])?.role || t?.role;
  const templateItem: TemplateItem = {
    id: t?.id,
    title,
    name: title,
    role,
    sharedWith: t?.sharedWith,
    templateFields: t?.templateAttributes,
    templateDocuments: t?.templateDocuments,
    createdBy: t?.createdBy,
    modifiedBy: t?.modifiedBy,
    lastModifiedTime: dateLocalFormat(new Date(t?.lastModifiedTime || Date.now())),
    createdTime: dateLocalFormat(new Date(t?.createdTime || Date.now())),
    ownerAccount: t?.accountId,
    ownerAccountName: t?.accountName,
  };
  return templateItem;
};

export const mapCertDocuments = (el: TemplateCertificationResponse): CertificationInfo => ({
  id: el?.id || uuidv4(),
  certificationType: el?.type,
  certificationStandard: el?.standard,
  certificationAgency: el?.agency,
  certificationValue: el?.value,
  certificationIdentification: el?.identification,
});
