import { ProColumns } from '@ant-design/pro-table';
import useListLocation from 'hooks/useListLocation';
import { GetLocationsOptionalParams } from 'hooks/useListLocation/typings';
import { useMemo } from 'react';
import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  CreateLocationOptionalParams,
  CreateLocationResponse,
  DeleteLocationOptionalParams,
  GetLocationByIdOptionalParams,
  GetLocationStatisticsByIdOptionalParams,
  ListLocationsOptionalParams,
  ListNetworksOptionalParams,
  ShareLocationRequest,
  ToggleArchiveAllVesselsOptionalParams,
  ToggleArchiveAllVesselsResponse,
  ToggleArchiveLocationOperationResponse,
  ToggleArchiveLocationOptionalParams,
  ToggleArchiveVesselsRequest,
  UpdateLocationRequest,
  UpdateLocationResponse,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

export const getLocations = (params?: ListLocationsOptionalParams) =>
  HttpClient.listLocations(params);

// export const getEmptyLocations = async (
//   params?: GetLocationsOptionalParams,
// ): Promise<GetLocationsResponse> => {
//   if (params) {
//     const res = await HttpClient.getLocations(params);
//     return res;
//   }
//   return {};
// };

// export const useEditTableLocations = (params?: GetLocationsOptionalParams) =>
//   useQuery(['locationsEditTable', params], () => getLocations(params), {
//     // disable refetch on window focus and switch tab
//     refetchOnWindowFocus: false,
//     refetchOnReconnect: false,
//   });

const useLocations = (params?: ListLocationsOptionalParams) =>
  useQuery(['locations', params], () => getLocations(params), {
    // disable refetch on window focus and switch tab
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  });

// export const useGetLocations = (params?: GetLocationsOptionalParams) =>
//   useQuery(['locationsAddEdit', params], () => getEmptyLocations(params), {
//     // disable refetch on window focus and switch tab
//     refetchOnWindowFocus: false,
//     refetchOnReconnect: false,
//   });

// const getLocationsByPageNumber = async (
//   pageNumber: number,
//   params?: GetLocationsOptionalParams,
// ) => {
//   const res = await getLocations({ ...params, pageNumber: pageNumber || 1 });
//   return {
//     ...res,
//   };
// };
// export const useInfiniteLocations = (params?: GetLocationsOptionalParams) =>
//   useInfiniteQuery({
//     queryKey: ['locationList', params?.locationName],
//     queryFn: ({ pageParam = 1 }) => getLocationsByPageNumber(pageParam, params),
//     getNextPageParam: (lastPage) => {
//       // if there is no next page, return undefined
//       if ((lastPage?.pageNumber || 0) < (lastPage?.totalPages || 0)) {
//         const nextPage = (lastPage?.pageNumber || 1) + 1;
//         return nextPage;
//       }
//       return undefined;
//     },
//   });
/* locations filter for table */
export const useLocationFilters = (params?: GetLocationsOptionalParams): ProColumns['filters'] => {
  const { data: locations } = useListLocation(params);
  const locationFilters = useMemo(
    () =>
      locations?.map((option) => ({
        text: option?.name || '',
        value: option?.id || '',
      })) || [],
    [locations],
  );
  return locationFilters;
};
export const useLocationURNFilters = (
  params?: GetLocationsOptionalParams,
): ProColumns['filters'] => {
  const { data: locations } = useListLocation(params);
  const locationFilters = useMemo(
    () =>
      locations?.map((option) => ({
        text: option?.name || '',
        value: option?.urn || '',
      })) || [],
    [locations],
  );
  return locationFilters;
};

// export const useVesselLocations = (params?: GetLocationsOptionalParams) =>
//   useQuery(['vesselLocations', params], () => getLocations(params), {
//     // disable refetch on window focus and switch tab
//     refetchOnWindowFocus: false,
//     refetchOnReconnect: false,
//   });

const getLocationById = (id: string, options?: GetLocationByIdOptionalParams) =>
  HttpClient.getLocationById(id, options);

export const useLocationById = (id: string, options?: ListLocationsOptionalParams) =>
  useQuery(['locationDataId', id, options], () => getLocationById(id, options));

const createLocationRequest = (
  options: CreateLocationOptionalParams,
): Promise<CreateLocationResponse> => HttpClient.createLocation(options);

export const useCreateLocation = (queryClient: QueryClient) =>
  useMutation((options: CreateLocationOptionalParams) => createLocationRequest(options), {
    onSuccess: () => {
      // queryClient.refetchQueries('locationsEditTable');
      queryClient.refetchQueries('locationDataId');
      // queryClient.refetchQueries('locationsAddEdit');
      queryClient.refetchQueries('locations');
      // queryClient.refetchQueries('mylocations');
      queryClient.refetchQueries('networks');
    },
  });

const updateLocationRequest = (
  id: string,
  options: UpdateLocationRequest,
): Promise<UpdateLocationResponse> =>
  HttpClient.updateLocation(id, {
    body: options,
  });

export const useUpdateLocationById = (queryClient: QueryClient) =>
  useMutation(
    ({ id, options }: { id: string; options: UpdateLocationRequest }) =>
      updateLocationRequest(id, options),
    {
      onSuccess: () => {
        //  queryClient.refetchQueries('locationsAddEdit');
        queryClient.refetchQueries('locations');
        // queryClient.refetchQueries('mylocations');
        queryClient.refetchQueries('networks');
        queryClient.refetchQueries('locationDataId');
        queryClient.refetchQueries('locationInfo');
      },
    },
  );

const getLocationNetworkDetails = (id: string, options?: GetLocationStatisticsByIdOptionalParams) =>
  HttpClient.getLocationStatisticsById(id, options);

export const useGetLocationNetworkDetails = (
  id: string,
  options?: GetLocationStatisticsByIdOptionalParams,
) => useQuery(['locationInfo', id, options], () => getLocationNetworkDetails(id, options));

export type PromiseSettledResultShareLocation =
  | PromiseFulfilledResult<ShareLocationRequest>
  | PromiseRejectedResult;

const shareMyLocation = (options: ShareLocationRequest): Promise<ShareLocationRequest> =>
  HttpClient.shareLocation({
    body: options,
  });

export const useShareLocations = (queryClient: QueryClient) =>
  useMutation(({ options }: { options: ShareLocationRequest }) => shareMyLocation(options), {
    onSuccess: () => {
      //  queryClient.refetchQueries('locationsAddEdit');
      queryClient.refetchQueries('locations');
      // queryClient.refetchQueries('mylocations');
      queryClient.refetchQueries('networks');
    },
  });

export type PromiseSettledResultDeleteLocation =
  | PromiseFulfilledResult<ToggleArchiveLocationOptionalParams>
  | PromiseRejectedResult;

const archiveLocationById = (ids: string[]): Promise<ToggleArchiveLocationOperationResponse> =>
  HttpClient.toggleArchiveLocation({ body: { archived: true, ids } });

const unarchiveLocationById = (ids: string[]): Promise<ToggleArchiveLocationOperationResponse> =>
  HttpClient.toggleArchiveLocation({ body: { archived: false, ids } });

const toggleArchiveAllVessels = (
  options?: ToggleArchiveAllVesselsOptionalParams,
): Promise<ToggleArchiveAllVesselsResponse> => HttpClient.toggleArchiveAllVessels(options);

export const useDeleteNetworkLocations = (queryClient: QueryClient) =>
  useMutation(({ ids }: { ids: Array<string> }) => archiveLocationById(ids), {
    onSuccess: () => {
      //  queryClient.refetchQueries('locationsAddEdit');
      queryClient.refetchQueries('locations');
      queryClient.refetchQueries('tradePartners');
      // queryClient.refetchQueries('mylocations');
      queryClient.refetchQueries('networks');
    },
  });

export const useCompanyLocations = () =>
  useMutation(({ params }: { params: ListLocationsOptionalParams }) => getLocations(params));

export const getNetworks = (params?: ListNetworksOptionalParams) => HttpClient.listNetworks(params);
export const useNetworks = (params?: ListNetworksOptionalParams) =>
  useQuery(['networks', params], () => getNetworks(params));

export const useGetLocationById = () =>
  useMutation(({ id, options }: { id: string; options?: GetLocationByIdOptionalParams }) =>
    getLocationById(id, options),
  );

// export const useMyLocations = (params?: GetLocationsOptionalParams) =>
//   useQuery(
//     ['mylocations', params],
//     () => getLocations({ ...params, tradePartnerStatus: ['Self'] }),
//     {
//       // disable refetch on window focus and switch tab
//       refetchOnWindowFocus: false,
//       refetchOnReconnect: false,
//     },
//   );

export const useArchiveAllVesselLocations = (queryClient: QueryClient) =>
  useMutation(
    ({ body }: { body: ToggleArchiveVesselsRequest }) => toggleArchiveAllVessels({ body }),
    {
      onSuccess: () => {
        // queryClient.refetchQueries('locationsAddEdit');
        queryClient.refetchQueries('locations');
        // queryClient.refetchQueries('mylocations');
        queryClient.refetchQueries('networks');
        queryClient.refetchQueries('tradePartners');
      },
    },
  );

export const useUnarchiveLocationById = (queryClient: QueryClient) =>
  useMutation(({ ids }: { ids: string[] }) => unarchiveLocationById(ids), {
    onSuccess: () => {
      //  queryClient.refetchQueries('locationsAddEdit');
      queryClient.refetchQueries('locations');
      // queryClient.refetchQueries('mylocations');
      queryClient.refetchQueries('networks');
      queryClient.refetchQueries('locationDataId');
      queryClient.refetchQueries('locationInfo');
      queryClient.refetchQueries('tradePartners');
    },
  });

const deleteLocation = (
  ids: string[],
  options?: DeleteLocationOptionalParams,
): Promise<PromiseSettledResult<void>[]> =>
  Promise.allSettled(ids.map((value) => HttpClient.deleteLocation(value, options)));

export const useDeleteLocations = (queryClient: QueryClient) =>
  useMutation(({ ids }: { ids: string[] }) => deleteLocation(ids), {
    onSuccess: () => {
      //   queryClient.refetchQueries('locationsAddEdit');
      queryClient.refetchQueries('locations');
      // queryClient.refetchQueries('mylocations');
      queryClient.refetchQueries('networks');
      queryClient.refetchQueries('tradePartners');
    },
  });

export default useLocations;
