import { InfoCircleOutlined } from '@ant-design/icons';
import { ProFormColumnsType } from '@ant-design/pro-form';
import { ColProps, RowProps } from 'antd';
import BizStepSelect from 'components/GSelect/BizStepSelect';
import DispositionSelect from 'components/GSelect/DispositionSelect';
import { ProductItem } from 'pages/Products/typings';
import { useTranslation } from 'react-i18next';

import { TimezoneSelect } from 'components/GSelect';
import TemplateSelect from 'components/GSelect/TemplateSelect';
import moment from 'moment';
import { DataItem as DocumentItem } from 'pages/Documents/Forms/typings';
import { useEventsStore } from 'pages/Events/hooks';
import { getTemplateFields } from 'utils';
import {
  CertDocuments,
  CertificationInfo,
  DataTemplate,
  Documents,
  EventDetails,
} from '../Sections';
import { AggregateProductItem } from './AggregationForm.fields';
import DataEntryMethod from './DataEntryMethod';
import { InputProducts, OutputProducts } from './TransformTables';
import { TransformFormFieldsProps } from './typings';

const colPropsFull = { xs: 24, sm: 24, md: 24, lg: 24 };
const rowProps: RowProps = { gutter: [24, 24] };
const colProps: ColProps = { xs: 24, sm: 12, md: 12, lg: 6 };
const colPropsNull: ColProps = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
const colPropsFullCsv = { xs: 24, sm: 24, md: 24, lg: 24 };

export type ColumnsType = {
  onEditProduct: (type: string, record?: ProductItem) => void;
  type: string;
};
export type DataItem = {
  location?: string;
  poNumber?: string;
  customData?: string;
  date?: string;
  time?: string;
  advancedField?: string;
  advancedField2?: string;
  advancedField3?: string;
  dataTemplate?: string;
  timeZone?: string;
  bizStep?: string;
  disposition?: string;
  ipProducts?: Array<AggregateProductItem>;
  opProducts?: Array<AggregateProductItem>;
  documents?: Array<DocumentItem>;
  customProperties?: Array<string>;
  certificationList?: Array<CertificationInfo>;
  csvData?: Array<
    DataItem & {
      productId?: string;
      lotID?: string;
      quantity?: number;
      [key: string]: any;
    }
  >;
};

export const TransformFormFields = ({
  disabledDate,
  onChangeDate,
  disabledTime,
  onChangeTemplate,
  timeValidation,
  hasAdvancedSettings,
  dataEntryMethod,
  lastEventDate,
  defaultUom,
  defaultTimezone,
  editableTableFormRef,
  isSerial,
  identifier,
  selectedInstances,
}: TransformFormFieldsProps): ProFormColumnsType<DataItem>[] => {
  const isManualEntry = dataEntryMethod === 'manual';
  const { t } = useTranslation('pages', { keyPrefix: 'events.transform' });
  const { template } = useEventsStore();

  return [
    {
      valueType: 'group',
      title: t('form_fields.input_products_grp_title'),
      columns: [
        {
          dataIndex: 'ipProducts',
          initialValue: [],
          renderFormItem: (_schema, _config, form) => (
            <InputProducts
              form={form}
              defaultUom={defaultUom}
              isSerial={isSerial}
              identifier={identifier}
              selectedInstances={selectedInstances}
            />
          ),
          colProps: {
            xs: 24,
            md: 24,
          },
        },
      ],
    },

    {
      valueType: 'divider',
      formItemProps: {
        className: 'no-margin-divider',
      },
      fieldProps: {
        className: 'no-margin-divider',
      },
      hideInForm: !isManualEntry,
    },
    {
      valueType: 'group',
      title: t('form_fields.output_products_grp_title'),
      columns: [
        {
          dataIndex: 'opProducts',
          initialValue: [],
          renderFormItem: (_schema, _config, form) => (
            <OutputProducts
              form={form}
              defaultUom={defaultUom}
              isSerial={isSerial}
              identifier={identifier}
            />
          ),
          colProps: {
            xs: 24,
            md: 24,
          },
        },
      ],
      hideInForm: !isManualEntry,
    },

    {
      valueType: 'divider',
      formItemProps: {
        className: 'no-margin-divider',
      },
      fieldProps: {
        className: 'no-margin-divider',
      },
    },
    {
      renderFormItem: () => <EventDetails />,
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['location'],
      },
      columns: ({ location }: DataItem) => [
        {
          title: t('form_fields.custom_data_title'),
          dataIndex: 'customData',
          fieldProps: {
            placeholder: t('form_fields.custom_data_placeholder'),
          },
          formItemProps: {
            rules: [
              {
                required: false,
                message: t('form_fields.custom_data_req'),
              },
            ],
          },
          renderFormItem: (row, config, form) => (
            <TemplateSelect
              onChange={(value, option) => onChangeTemplate?.(form, option, value, location)}
            />
          ),
          colProps,
        },
      ],
    },
    {
      title: t('form_fields.data_entry_title'),
      dataIndex: 'dataEntryMethod',
      renderFormItem: () => <DataEntryMethod />,
      colProps: {
        xs: 24,
        md: 18,
      },
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['date'],
      },
      columns: ({ date = undefined }: DataItem) => [
        {
          title: t('form_fields.date_title'),
          valueType: 'date',
          dataIndex: 'date',
          initialValue: null,
          hideInForm: !isManualEntry,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.date_req'),
              },
            ],
          },
          fieldProps: (form) => ({
            placeholder: t('form_fields.date_placeholder'),
            className: 'full-width',
            disabledDate,
            onChange: (value: string) => onChangeDate?.(form, value),
          }),
          colProps,
        },
        {
          title: t('form_fields.time_title'),
          valueType: 'time',
          dataIndex: 'time',
          initialValue: '12:00:00',
          className: 'timecol',
          hideInForm: !isManualEntry,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.time_req'),
              },
              ...(timeValidation?.(date) || []),
            ],
          },
          fieldProps: {
            placeholder: t('form_fields.time_placeholder'),
            className: 'full-width',

            // check is same day as date
            disabledTime:
              moment(date).isValid() &&
              moment(lastEventDate).isValid() &&
              moment(new Date(String(lastEventDate))).isSame(date, 'day')
                ? disabledTime
                : undefined,
          },
          colProps,
        },
      ],
    },

    {
      title: t('form_fields.time_zone_title'),
      dataIndex: 'timeZone',
      valueType: 'select',
      initialValue: defaultTimezone,
      formItemProps: {
        rules: [
          {
            required: true,
            message: t('form_fields.time_zone_req'),
          },
        ],
      },
      fieldProps: {
        placeholder: t('form_fields.time_zone_placeholder'),
      },
      renderFormItem: () => <TimezoneSelect />,

      colProps: !isManualEntry || !hasAdvancedSettings ? colPropsNull : colProps,
    },
    {
      title: t('form_fields.bizstep_title'),
      dataIndex: 'bizStep',
      initialValue: 'urn:epcglobal:cbv:bizstep:commissioning',
      valueType: 'select',
      renderFormItem: () => <BizStepSelect />,
      formItemProps: {
        rules: [
          {
            required: true,
            message: t('form_fields.bizstep_req'),
          },
        ],
      },
      tooltip: {
        icon: <InfoCircleOutlined />,
        title: t('form_fields.bizstep_tooltip'),
        key: null,
        type: '',
        props: null,
      },
      colProps: !isManualEntry || !hasAdvancedSettings ? colPropsNull : colProps,
    },
    {
      title: t('form_fields.disposition_title'),
      dataIndex: 'disposition',
      initialValue: 'urn:epcglobal:cbv:disp:active',
      valueType: 'select',
      renderFormItem: () => <DispositionSelect />,
      formItemProps: {
        rules: [
          {
            required: true,
            message: t('form_fields.disposition_req'),
          },
        ],
      },
      fieldProps: {
        placeholder: t('form_fields.disposition_placeholder'),
      },
      tooltip: {
        icon: <InfoCircleOutlined />,
        title: t('form_fields.disposition_tooltip'),
        key: null,
        type: '',
        props: null,
      },
      colProps: !isManualEntry || !hasAdvancedSettings ? colPropsNull : colProps,
    },
    {
      valueType: 'divider',
      formItemProps: {
        className: 'no-margin-divider',
      },
      fieldProps: {
        className: 'no-margin-divider',
      },
    },
    {
      dataIndex: 'csvData',
      renderFormItem: (row, record, form) => (
        <DataTemplate form={form} editableTableFormRef={editableTableFormRef} />
      ),
      colProps: colPropsFullCsv,
    },
    {
      valueType: 'formSet',
      dataIndex: 'customProperties',
      initialValue: [],
      columns: getTemplateFields(template),
      rowProps,
      hideInForm: !template,
    },
    {
      dataIndex: 'certificationList',
      rowProps,
      renderFormItem: (row, record, form) => <CertDocuments form={form} />,
      hideInForm:
        !template || !template?.templateAttributes || !template?.allowCertificationDocuments,
    },
    {
      valueType: 'divider',
      formItemProps: {
        className: 'no-margin-divider',
      },
      fieldProps: {
        className: 'no-margin-divider',
      },
    },
    {
      dataIndex: 'documents',
      initialValue: [],
      renderFormItem: (_row, _config, form) => <Documents form={form} />,
      colProps: colPropsFull,
    },
  ];
};

export default TransformFormFields;
