import { Segmented, Space, Tabs } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { SegmentedValue } from 'antd/lib/segmented';
import { TitleBar } from 'components';
import { flatten } from 'flat';
import { useEventCountModal } from 'pages/Events';
import { ProductItem } from 'pages/Products';
import { useShipmentStore } from 'pages/Shipments';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEventById, useEventMapById, useProductById } from 'services/api';
import { EventNodeContainer, EventNodeStub } from 'services/api/client/src';
import { getNodeLifeCycleTime, getProductsT, getStringifiedVal } from 'utils';
import { isObject } from 'utils/locations';
import * as XLSX from 'xlsx';
import { EventDiagram, EventList } from '.';
import EventContext from './EventContext';
import EventMap from './EventMap';
import {
  EventContextProps,
  EventTabs,
  EventTitleProps,
  FlatObject,
  GeneralActionItemsProps,
  LocationState,
} from './typings';
import useEventListActions from './useEventListActions';

const GeneralActionItems = ({ t }: GeneralActionItemsProps): Array<ItemType> => [
  {
    key: 'manage',
    label: t('manage_grp'),
    type: 'group',
    children: [
      {
        key: 'download_csv',
        label: t('actions.download_csv'),
      },
    ],
  },
];

const GeneralEventListActionItems = ({ t }: GeneralActionItemsProps): Array<ItemType> => [
  {
    key: 'download_xlsx',
    label: t('actions.download_xlsx'),
  },
];
const EventsTitle: FC<EventTitleProps> = ({ product, instances, tab, t }) => {
  const sections = useMemo(() => [t('details'), t('documents')], [t]);
  const [section, setSection] = useState<string | number>(sections[0]);
  const [queryParams] = useSearchParams();
  const eventId = queryParams?.get('eventId') || '1';
  const { onDownloadExcel } = useEventListActions({ eventIds: [eventId] });
  const navigate = useNavigate();
  const location = useLocation() as LocationState;
  const isContainerParam = queryParams?.get('isContainer') || false;

  const onDownloadCsv = () => {
    const workbook = XLSX.utils.book_new();

    const nodes = instances?.nodes || [];
    const events = nodes.flatMap((item) => item?.events || []);
    events.forEach((item, idx) => {
      const isContainer =
        !!item?.container?.identifier || (item?.containers?.[0]?.products?.length || 0) > 0;
      const newObject: FlatObject = {};
      const flatArray: Array<FlatObject> = [];
      Object.keys(item).forEach((key) => {
        const value = item?.[key as keyof EventNodeStub] || '';
        const newValue = getStringifiedVal(value);

        /* flatten if value is an object */
        if (isObject(value) && key !== 'container') {
          /* remove array value from value before flatten */

          const flattenedobject = flatten(value, {
            delimiter: '_',
          }) as FlatObject;
          Object.keys(flattenedobject).forEach((k) => {
            newObject[`${key}_${k}`] = flattenedobject[k];
          });
        } else {
          switch (key) {
            /* ignore some keys */
            case 'inputProducts':
              break;
            case 'products':
              break;
            case 'outputProducts':
              break;
            case 'container':
              /* flatten all keys expect products */
              // eslint-disable-next-line no-case-declarations
              const containerObject = value as EventNodeContainer;
              Object.keys(containerObject).forEach((k) => {
                if (k !== 'products') {
                  newObject[`${key}_${k}`] = containerObject?.[k as keyof EventNodeContainer];
                }
              });
              break;

            default:
              newObject[key] = newValue;
              break;
          }
        }
      });

      // loop over products , inputProducts, outputProducts, containerProducts

      item?.inputProducts?.forEach((p) => {
        const object = {
          ...newObject,
          type: 'Input',
          ...p,
        };
        flatArray.push(object);
      });

      item?.outputProducts?.forEach((p) => {
        const object = {
          ...newObject,
          type: 'Output',
          ...p,
        };
        flatArray.push(object);
      });
      if (!isContainer) {
        item?.products?.forEach((p) => {
          const object = {
            ...newObject,
            ...p,
          };
          flatArray.push(object);
        });
      } else {
        (item?.container || item?.containers?.[0])?.products?.forEach((p) => {
          const object = {
            ...newObject,
            ...p,
          };
          flatArray.push(object);
        });
      }
      const sheet = XLSX.utils.json_to_sheet(flatArray);
      XLSX.utils.book_append_sheet(workbook, sheet, `${item.eventType}-${idx}`);
    });
    XLSX.writeFile(workbook, `Events_${product?.name || ''}.xlsx`);
  };

  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'download_csv':
        onDownloadCsv();
        break;

      case 'download_xlsx':
        onDownloadExcel();
        break;

      default:
        break;
    }
  };

  const onSegmentedButtonClick = (value: SegmentedValue) => {
    setSection(value);
    switch (value) {
      case t('details'):
        break;
      case t('documents'):
        navigate(
          {
            pathname: 'documents',
            search: isContainerParam
              ? `?eventId=${eventId || '1'}&isContainer=${isContainerParam}`
              : `?eventId=${eventId || '1'}`,
          },
          { state: location.state },
        );
        break;

      default:
        break;
    }
  };
  const onBack = () => {
    const locationFrom = location.state?.from;
    if (locationFrom) {
      navigate(`${locationFrom}`);
    } else {
      navigate('../../');
    }
  };

  return (
    <TitleBar
      className="page-header"
      title={
        <Space>
          {product?.name || ''}
          <Segmented
            value={section || sections[0]}
            options={sections}
            onChange={onSegmentedButtonClick}
          />
        </Space>
      }
      onBack={onBack}
      actionItems={tab === 'list' ? GeneralEventListActionItems({ t }) : GeneralActionItems({ t })}
      onActionItemClick={onActionItemClick}
    />
  );
};
const EventDetails = () => {
  const [currentTab, setCurrentTab] = useState<EventTabs>('map');
  const { t } = useTranslation('pages', { keyPrefix: 'products.event_details' });
  const { productId = '1', lotId = '' } = useParams();
  const [queryParams] = useSearchParams();
  const eventId = queryParams?.get('eventId') || undefined;
  const isContainer = queryParams?.get('isContainer') || false;

  useEventCountModal();
  const { data: currentProduct } = useProductById(productId);
  const { data: event } = useEventById(eventId);
  // const { data: productInstanceArray } = useMultipleProductInstances(
  //   !isContainer ? [lotId] : [],
  //   isContainer ? [lotId] : [],
  // );
  const { inventoryItem } = useShipmentStore();

  // const productInstance = productInstanceArray?.[0];
  const [showAllProducts, setShowAllProducts] = useState<boolean>(true);
  const { data: instances } = useEventMapById(eventId, {
    instanceId: lotId || undefined,
    ...(isContainer
      ? {
          containerId: lotId,
          instanceId: inventoryItem?.containerItems?.[0]?.instanceId || lotId || undefined,
        }
      : {}),
    showAllProducts,
  });
  const lifeCyle = useMemo(() => getNodeLifeCycleTime(instances?.nodes), [instances]);

  const product: ProductItem = {
    id: productId,
    epcUrn: currentProduct?.urn || '',
    name: event?.containers?.[0]?.containerIdentifier
      ? `${getProductsT('sscc')}: ${event?.containers?.[0]?.containerIdentifier || ''}`
      : currentProduct?.name || '',
    currentInventory: currentProduct?.currentInventory || 0,
  };
  const contextValue = useMemo<EventContextProps>(
    () => ({
      lifeCyle,
      showAllProducts,
      setShowAllProducts,
      inventoryItem,
    }),
    [lifeCyle, showAllProducts, inventoryItem],
  );

  const onTabChange = (activeKey: EventTabs) => {
    setCurrentTab(activeKey);
  };
  return (
    <>
      <EventsTitle product={product} tab={currentTab} event={event} instances={instances} t={t} />
      <EventContext.Provider value={contextValue}>
        <Tabs defaultActiveKey="map" onChange={(key) => onTabChange(key as EventTabs)}>
          <Tabs.TabPane key="map" tab={t('map')}>
            {currentTab === 'map' && (
              <EventMap
                t={t}
                product={currentProduct}
                event={event}
                primaryId={lotId}
                isContainer={isContainer}
                eventId={eventId}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane key="diagram" tab={t('diagram')}>
            {currentTab === 'diagram' && (
              <EventDiagram
                t={t}
                product={currentProduct}
                event={event}
                primaryId={lotId}
                eventId={eventId}
                isContainer={isContainer}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane key="list" tab={t('list')}>
            {currentTab === 'list' && (
              <EventList eventId={eventId} product={currentProduct} event={event} />
            )}
          </Tabs.TabPane>
        </Tabs>
      </EventContext.Provider>
      <Outlet />
    </>
  );
};
export default React.memo(EventDetails);
