import { Col, Row } from 'antd';
import { ShareDocument } from 'components';
import useListLocation from 'hooks/useListLocation';
import useModalVisibility from 'hooks/useModalVisibility';
import { DocumentItem } from 'pages/Documents/typings';
import { SelectedProps } from 'pages/Network/forms/ShareDocument/typings';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useTradePartnerById } from 'services/api';
import { useGetLocationNetworkDetails, useLocationById } from 'services/api/useLocations';
import { flattenAddress } from 'utils';
import { getAccountType } from 'utils/network';
import {
  LocationHeader,
  LocationInfoBar,
  ShipmentContainer,
  SingleMapLocation,
  SkeletonLoader,
  TableContainer,
} from './components';
import { AttributeCols } from './components/TableContainer/AttributeTable/typings';
import { ShareLocation } from './forms';
import useLocationStore from './hook/useLocationStore';
import styles from './index.module.less';
import { NetworkItem } from './typings';

const colProps1 = { xs: 24, sm: 24, md: 16, lg: 16 };
const colProps2 = { xs: 24, sm: 24, md: 8, lg: 8 };

const ViewLocation: FC = () => {
  const { locationId = '1', companyId = '1' } = useParams();
  const setLocationData = useLocationStore((state) => state.setLocationData);
  const shareLocModal = useModalVisibility(false);
  const navigate = useNavigate();

  const [documentToShare, setDocumentToShare] = useState<SelectedProps>();

  const { data: item = {} } = useLocationById(locationId);
  const { data: dataLocations, isLoading: isLocationsLoading } = useListLocation({
    partnerIds: [String(companyId)],
    pageSize: 9999,
  });
  const { data: companyData } = useTradePartnerById(String(companyId));
  const companyLocations = useMemo(() => dataLocations || [], [dataLocations]);

  const { data: summaryInfo = {}, isLoading: isSummaryLoading } = useGetLocationNetworkDetails(
    String(locationId),
  );
  const shareDocModal = useModalVisibility(false);

  const isLoading = useMemo(
    () => isLocationsLoading || isSummaryLoading,
    [isLocationsLoading, isSummaryLoading],
  );

  const locProps = useMemo<NetworkItem>(
    () => ({
      id: item.id || '',
      name: item.name,
      company: item.tradePartnerName,
      wcId: item.wholechainId || '',
      locationAddress: item.address?.displayAddress
        ? item.address?.displayAddress
        : flattenAddress(item.address),
      lat: Number(item.address?.geoCoordinates?.latitude),
      lng: Number(item.address?.geoCoordinates?.longitude),
      companyId: item.tradePartnerId,
      connectionType: summaryInfo?.connectionType,
      shipmentsReceived: summaryInfo?.shipmentsReceived,
      shipmentsSent: summaryInfo?.shipmentsSent,
      contact: item?.contact,
      urn: item?.urn,
      tradePartnerUrn: item?.tradePartnerUrn,
      digitalLinkUrl: companyData?.digitalLinkUrl,
      accountType: getAccountType(
        item?.networkStatus === 'Self' || false,
        item?.networkStatus === 'Wholechain' || false,
        item?.networkStatus === 'NonWholechain' && !!item?.wholechainId,
      ),
      isLocationView: true,
    }),
    [
      companyData?.digitalLinkUrl,
      item.address,
      item?.contact,
      item.id,
      item.name,
      item?.networkStatus,
      item.tradePartnerId,
      item.tradePartnerName,
      item?.tradePartnerUrn,
      item?.urn,
      item.wholechainId,
      summaryInfo?.connectionType,
      summaryInfo?.shipmentsReceived,
      summaryInfo?.shipmentsSent,
    ],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    setLocationData(locProps);
  }, [item, setLocationData, locProps]);

  return (
    <>
      <ShareDocument
        modal={shareDocModal}
        documentToShare={documentToShare}
        setDocumentToShare={() => setDocumentToShare(undefined)}
      />
      {shareLocModal.visible && <ShareLocation modal={shareLocModal} locationToShare={locProps} />}
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          <LocationHeader
            shareDocModal={shareDocModal}
            companyLocations={companyLocations}
            shareLocModal={shareLocModal}
            isLoading={isLoading}
          />
          <LocationInfoBar summaryInfo={summaryInfo} isLoading={isLoading} />
        </>
      )}

      <Row gutter={[20, 20]}>
        <Col {...colProps1}>
          <ShipmentContainer />
        </Col>
        <Col {...colProps2}>
          <SingleMapLocation companyLocations={[item]} />
        </Col>
      </Row>
      <div className={styles.container}>
        <TableContainer
          isMyLocations={item?.networkStatus === 'Self' || false}
          onEditAttribute={(record: AttributeCols) => {
            if (record) {
              navigate('editLocationId?tab=attribute');
            }
          }}
          companyLocations={[item]}
          onShareDocument={(record: DocumentItem) => {
            if (record) {
              setDocumentToShare({
                value: record?.id,
                label: record?.title,
              });
              shareDocModal.show();
            }
          }}
        />
      </div>
      <Outlet />
    </>
  );
};

export default React.memo(ViewLocation);
