import { InfoCircleOutlined } from '@ant-design/icons';
import { ProFormColumnsType } from '@ant-design/pro-form';
import { ColProps, RowProps } from 'antd';
import { BizStepSelect, DispositionSelect, TimezoneSelect } from 'components/GSelect';
import { DataItem as DocumentItem } from 'pages/Documents/Forms/typings';
import { ProductItem } from 'pages/Products/typings';
import { useTranslation } from 'react-i18next';
import {
  getDisabledDate,
  getDisabledTimeDependency,
  getLocalMaxEventDate,
  getMaxEventTime,
  onChangeFieldDate,
} from 'utils';
import { CertificationInfo, Documents, EventDetails } from '../Sections';
import { AggregateProducts } from './TransformTables';
import { AggregationFormFieldsProps } from './typings';

const colPropsFull: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };
const colProps: ColProps = { xs: 24, sm: 12, md: 12, lg: 6 };
const colPropsNull = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
const rowProps: RowProps = { gutter: [24, 24] };

export interface AggregateProductItem extends ProductItem {
  id: string;
  parentProductId?: string;
  productId?: string;
  name: string;
  primaryId: string;
  lotID?: string;
  lotSerial?: string;
  quantity?: number;
  currentInventory?: number;
  currentInventoryTotal?: number;
  isContainer?: boolean;
  instanceInventory?: number;
  eventDate?: string;
  date?: string;
  time?: string;
  customProperties?: Array<string>;
}
export type DataItem = {
  location?: string;
  poNumber?: string;
  sscc?: string;
  date?: string;
  time?: string;
  timeZone?: string;
  bizStep?: string;
  disposition?: string;
  eventProducts: Array<AggregateProductItem>;
  documents?: Array<DocumentItem>;
  csvData?: Array<DataItem>;
  certificationList?: Array<CertificationInfo>;
  customProperties?: Array<string>;
};

export const AggregationFormFields = ({
  hasAdvancedSettings,
  dataEntryMethod,
  defaultTimezone,
  isSerial,
  identifier,
  selectedInstances,
  timeValidation,
}: AggregationFormFieldsProps): ProFormColumnsType<DataItem>[] => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.aggregate' });
  const isManualEntry = dataEntryMethod === 'manual';

  return [
    {
      renderFormItem: () => <EventDetails />,
    },
    {
      title: t('form_fields.scss_title'),
      dataIndex: 'sscc',
      formItemProps: {
        rules: [
          {
            required: true,
            message: t('form_fields.scss_req'),
          },
          {
            whitespace: true,
            message: t('form_fields.scss_req'),
          },
        ],
      },
      fieldProps: {
        placeholder: t('form_fields.scss_placeholder'),
      },
      colProps,
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['date', 'time', 'eventProducts'],
      },
      columns: ({ eventProducts, date, time }: DataItem) => [
        {
          title: t('form_fields.date_title'),
          valueType: 'date',
          dataIndex: 'date',
          initialValue: null,
          hideInForm: !isManualEntry,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.date_req'),
              },
            ],
          },

          fieldProps: (form) => ({
            placeholder: t('form_fields.date_placeholder'),
            className: 'full-width',
            disabledDate: getDisabledDate(getLocalMaxEventDate(eventProducts)),
            onChange: (val: string) =>
              onChangeFieldDate(form, val, getLocalMaxEventDate(eventProducts)),
          }),
          colProps,
        },
        {
          title: t('form_fields.time_title'),
          valueType: 'time',
          dataIndex: 'time',
          initialValue: '12:00:00',
          hideInForm: !isManualEntry,
          fieldProps: {
            placeholder: t('form_fields.time_placeholder'),
            className: 'full-width',
            // check is same day as date
            disabledTime: getDisabledTimeDependency(getMaxEventTime(eventProducts)),
          },

          className: 'timecol',
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.time_req'),
              },
              ...(timeValidation?.(date) || []),
            ],
          },

          colProps,
        },
        {
          valueType: 'group',
          rowProps,
          columns: [
            {
              title: t('form_fields.time_zone_title'),
              dataIndex: 'timeZone',
              valueType: 'select',
              initialValue: defaultTimezone,
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.time_zone_req'),
                  },
                ],
              },
              fieldProps: {
                placeholder: t('form_fields.time_zone_placeholder'),
              },
              renderFormItem: () => <TimezoneSelect />,

              colProps: !hasAdvancedSettings ? colPropsNull : colProps,
            },
            {
              title: t('form_fields.bizstep_title'),
              dataIndex: 'bizStep',
              initialValue: 'urn:epcglobal:cbv:bizstep:packing',
              valueType: 'select',
              renderFormItem: () => <BizStepSelect />,

              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.bizstep_req'),
                  },
                ],
              },
              tooltip: {
                icon: <InfoCircleOutlined />,
                title: t('form_fields.bizstep_tooltip'),
                key: null,
                type: '',
                props: null,
              },
              colProps: !hasAdvancedSettings ? colPropsNull : colProps,
            },
            {
              title: t('form_fields.disposition_title'),
              dataIndex: 'disposition',
              initialValue: 'urn:epcglobal:cbv:disp:in_progress',
              valueType: 'select',
              renderFormItem: () => <DispositionSelect />,
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.disposition_req'),
                  },
                ],
              },
              tooltip: {
                icon: <InfoCircleOutlined />,
                title: t('form_fields.disposition_tooltip'),
                key: null,
                type: '',
                props: null,
              },
              colProps: !hasAdvancedSettings ? colPropsNull : colProps,
            },
          ],
        },
        {
          dataIndex: 'eventProducts',
          initialValue: [],
          renderFormItem: (_schema, _config, form) => (
            <AggregateProducts
              form={form}
              date={date}
              time={time}
              isSerial={isSerial}
              identifier={identifier}
              selectedInstances={selectedInstances}
            />
          ),
          colProps: colPropsFull,
        },
        {
          valueType: 'divider',
        },
      ],
    },

    {
      dataIndex: 'documents',
      initialValue: [],
      renderFormItem: (_row, _config, form) => <Documents form={form} />,
      colProps: colPropsFull,
    },
  ];
};

export default AggregationFormFields;
