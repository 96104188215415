import type { ActionType, ProColumns } from '@ant-design/pro-table';
import { TablePaginationConfig, Typography } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import GTable from 'components/GTable';
import { useTableSearchFilter } from 'hooks';
import { UseModalVisibilityReturnType } from 'hooks/useModalVisibility';
import React, { FC, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { usePOSearch } from 'services';
import {
  GetGlobalSearchPaginatedProductsOptionalParams,
  GetPaginatedProductsOptionalParams,
  GlobalSearchProductResponse,
} from 'services/api/client/src';
import EventTable from './EventTable';
import styles from './index.module.less';

type ColumnsType = {
  t?: TFunction<'pages', 'products'>;
  supportModal?: UseModalVisibilityReturnType;
  productNameSearchfilter?: ProColumns;
};

const columns = ({
  t,
  productNameSearchfilter,
}: ColumnsType): Array<ProColumns<GlobalSearchProductResponse>> => [
  {
    title: t?.('col_product'),
    dataIndex: 'name',
    hideInSetting: true,
    render: (text, record) => (
      <div>
        <Typography.Text strong>{`${record?.name} `}</Typography.Text>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '20px' }}>
          <Typography.Text type="secondary">{`Location: ${record?.name || ''}`}</Typography.Text>
          <Typography.Text type="secondary">{`Location: ${record?.name || ''}`}</Typography.Text>
          <Typography.Text type="secondary">{`Location: ${record?.name || ''}`}</Typography.Text>
          <Typography.Text type="secondary">{`Location: ${record?.name || ''}`}</Typography.Text>
        </div>
      </div>
    ),
    ellipsis: true,
    ...productNameSearchfilter,
  },
];

const SearchTable: FC = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'global_search.search_table' });
  const { purchaseOrder = '' } = useParams();
  const actionRef = useRef<ActionType>();
  const [params, setParams] = useState<GetGlobalSearchPaginatedProductsOptionalParams>({
    pageNumber: 1,
    pageSize: 10,
    purchaseOrder,
  });
  const { data, isLoading } = usePOSearch(params);
  const { filters: productNameSearchfilter } = useTableSearchFilter({
    title: t('col_product'),
    onSearch: (text) => {
      setParams({ ...params, name: text });
    },
  });

  const expandedRowRender = (row: GlobalSearchProductResponse) => (
    <EventTable productId={row?.id} />
  );

  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
  ) => {
    const apiFilters: GetPaginatedProductsOptionalParams = {
      sharingPolicies: (filters?.sharingPolicy as Array<string>) || undefined,
    };
    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        ...apiFilters,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
        pageNumber: 1,
      });
    } else {
      setParams({
        ...params,
        ...apiFilters,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
      });
    }
  };

  return (
    <GTable<GlobalSearchProductResponse>
      actionRef={actionRef}
      showHeader={false}
      columns={columns({ t, productNameSearchfilter })}
      headerTitle={
        <Typography.Text className={styles.potitle}>{`${t(
          'po_title',
        )} #: ${purchaseOrder}`}</Typography.Text>
      }
      columnsState={{
        defaultValue: {
          blockchain: {
            show: false,
          },
        },
        persistenceKey: 'pages-products',
      }}
      loading={isLoading}
      value={data?.results}
      enableRowSelection
      recordCreatorProps={false}
      pagination={{
        defaultPageSize: params.pageSize,
        total: data?.totalItems,
        showTotal: (total) => t('search_table_pagi', { total }),
      }}
      onTableChange={onTableChange}
      scroll={{ x: 915 }}
      expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
      options={{
        setting: false,
        reload: false,
      }}
    />
  );
};

export default React.memo(SearchTable);
