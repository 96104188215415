import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { Modal, Space, message } from 'antd';
import { Actions, Loading } from 'components';
import { useModalNavVisibility } from 'hooks/useModalVisibility';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAccount } from 'services/api';
import { UpdateDocumentRequest } from 'services/api/client/src';
import { useDocumentByIdWithDownload, useUpdateDocument } from 'services/api/documents';
import { dateLocaleFormat, errorHandler, generateDocTags } from 'utils';
import { useDocumentActions } from '../hooks';
import styles from '../index.module.less';
import ViewDocumentFormFields from './ViewDocumentForm.fields';
import { ActionItemsFn, DataItem, ViewDocFormProps } from './typings';

const rowProps = { gutter: 32 };
const ActionItems: ActionItemsFn = ({ t }) => [
  {
    label: t('view_document.actions.edit'),
    key: 'edit',
  },
  {
    label: t('view_document.actions.download'),
    key: 'download',
  },
  {
    label: t('view_document.actions.archive'),
    key: 'archive',
  },
];
const ViewDocumentForm: FC<ViewDocFormProps> = ({ docId, onModalClose }) => {
  const { data: account } = useAccount();
  const { t } = useTranslation('pages', { keyPrefix: 'documents' });
  const modal = useModalNavVisibility(true, !docId);
  const { documentId = docId || '' } = useParams();
  const editableFormRef = useRef<ProFormInstance<DataItem>>();
  const queryClient = useQueryClient();
  const updateDocument = useUpdateDocument(queryClient);
  const { onDownloadDocument, onArchiveDocument } = useDocumentActions();
  const [isEdit, setEditMode] = useState<boolean>();

  const { data, isLoading } = useDocumentByIdWithDownload(documentId);

  const documentData = data?.document;
  const isOwner = useMemo(
    () => documentData?.ownerAccount === account?.id,
    [account, documentData],
  );
  const attachment = data?.attachment;
  const document: DataItem = useMemo(
    () => ({
      id: documentData?.id || '',
      title: documentData?.title || '',
      documentType: documentData?.documentType?.toLocaleLowerCase(),
      privacy: documentData?.privacy || '',
      blockchain: documentData?.blockchain || '',
      agency: documentData?.certificationAgency || '',
      certificationStandard: documentData?.certificationStandard || '',
      identification: documentData?.certificationId || '',
      purchaseOrder: documentData?.purchaseOrderNumber || '',
      documentTypeName: documentData?.documentTypeName || '',
      expirationDate: documentData?.expirationDate
        ? dateLocaleFormat(new Date(documentData?.expirationDate || ''))
        : '',
      expirationDateAlert: documentData?.expirationAlertDate
        ? dateLocaleFormat(new Date(documentData?.expirationAlertDate || ''))
        : '',
      documentURL: documentData?.url,
      documentAttachment: attachment || '',
      locations: documentData?.locations || [],
      products: documentData?.products || [],
      tradePartners: documentData?.tradePartners || [],
      locationArray: documentData?.locations?.map((item) => item?.id || '') || [],
      productArray: documentData?.products?.map((item) => item?.id || '') || [],
      tradePartnerArray: documentData?.tradePartners?.map((item) => item?.id || '') || [],
      tags: generateDocTags({
        locations: documentData?.locations || [],
        products: documentData?.products || [],
        tradePartners: documentData?.tradePartners || [],
        events: documentData?.events || [],
      }),
      shipFrom: documentData?.shipFrom,
      shipTo: documentData?.shipTo,
      sID: documentData?.sid,
      cID: documentData?.cid,
      fOB: documentData?.fob,
      shipDate: documentData?.shipDate
        ? dateLocaleFormat(new Date(documentData?.shipDate || ''))
        : '',
      carrier: documentData?.carrier,
      items: documentData?.items,
      quantitiesOfShippedItems: documentData?.quantitiesOfShippedItems,
    }),
    [attachment, documentData],
  );

  const onCancel = useCallback(() => {
    editableFormRef?.current?.resetFields();
    setEditMode(false);
  }, []);

  const onClose = useCallback(() => {
    const hasFilled = editableFormRef?.current?.isFieldsTouched();

    switch (true) {
      case hasFilled && isEdit:
        Modal.warning({
          title: t?.('warning.unsaved_changes_title'),
          content: t?.('warning.unsaved_changes_desc'),
          okText: t?.('warning.unsaved_changes_submit'),
          cancelText: t?.('warning.unsaved_changes_cancel'),
          onOk: onCancel,
          cancelButtonProps: {
            type: 'primary',
            shape: 'round',
            ghost: true,
          },
          okButtonProps: {
            type: 'primary',
            shape: 'round',
          },
          centered: true,
          okCancel: true,
        });
        break;
      case isEdit:
        onCancel();
        break;
      default:
        modal.hide();
        if (onModalClose) {
          onModalClose();
        }
        break;
    }
  }, [isEdit, t, onCancel, onModalClose, modal]);

  const onFinish = async (formData: DataItem) => {
    if (isEdit) {
      try {
        const reqBody: UpdateDocumentRequest = {
          id: document?.id || '',
          privacy: formData.privacy,
          productsIds: formData?.productArray,
          locationsIds: formData?.locationArray,
          tradePartnersIds: formData?.tradePartnerArray,
        };
        const res = await updateDocument.mutateAsync({ id: document?.id || '', document: reqBody });
        message.success(t('view_document.update_success', { id: res?.title || '' }));
        modal.hide();
        if (onModalClose) {
          onModalClose();
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    } else {
      modal.hide();
      if (onModalClose) {
        onModalClose();
      }
    }
  };

  const onRowActionClick = (actionKey: string) => {
    switch (actionKey) {
      case 'download':
        onDownloadDocument(document);
        break;
      case 'archive':
        onArchiveDocument(document);
        modal.hide();
        if (onModalClose) {
          onModalClose();
        }
        break;
      case 'edit':
        setEditMode(true);
        break;

      default:
        break;
    }
  };

  if (isLoading) {
    return <Loading isFloating />;
  }

  return (
    <BetaSchemaForm<DataItem>
      formRef={editableFormRef}
      layoutType="ModalForm"
      columns={ViewDocumentFormFields({ t, document, isEdit, isOwner })}
      grid
      title={
        isEdit ? `${t('view_document.actions.edit')} ${document?.title}` : document?.title || ''
      }
      visible={modal.visible}
      rowProps={rowProps}
      initialValues={document}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        width: '80%',
        ...modal,
        className: styles.viewdocumentmodal,
        onCancel: onClose,
      }}
      submitter={{
        searchConfig: {
          submitText: isEdit ? t('view_document.save') : t('view_document.update_text'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          onClick: onClose,
          type: 'primary',
          ghost: true,
        },
        render: ({ form }, defaultDoms) =>
          isEdit ? (
            defaultDoms
          ) : (
            <Space>
              <Actions
                className="actions"
                buttonProps={{
                  ghost: true,
                }}
                items={ActionItems({ t })}
                onClick={onRowActionClick}
                actionPayload={form}
              />
              {defaultDoms[1]}
            </Space>
          ),
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};

export default React.memo(ViewDocumentForm);
