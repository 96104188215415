import { ProColumns } from '@ant-design/pro-table';
import { queryClient as qClient } from 'index';
import { useMemo } from 'react';
import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  ChangeArchivedProductRequest,
  CreateProductRequest,
  CreateProductResponse,
  GetContainerInventoryOptionalParams,
  GetGlobalSearchPaginatedProductsOptionalParams,
  GetGlobalSearchPaginatedProductsResponse,
  GetPaginatedProductInventoryOptionalParams,
  GetPaginatedProductInventoryResponse,
  GetPaginatedProductsOptionalParams,
  GetProductByIdOptionalParams,
  GetProductByIdResponse,
  InventoryResponse,
  PaginatedInventoryResponse,
  UpdateProductRequest,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';
import httpClient from 'services/utils/security/HttpClient';
import { getInventoryItem, shipActionInvItem } from 'utils';
import {
  CreateSubProductRequest,
  RemoveSubProductRequest,
  UpdateSubProductsRequest,
} from './typings';
/* get products */
const getProducts: typeof HttpClient.getPaginatedProducts = (filters) =>
  HttpClient.getPaginatedProducts(filters);
/* get product instances */
export const getProductInstances = (
  id: string,
  params?: GetPaginatedProductInventoryOptionalParams,
) => (id ? HttpClient.getPaginatedProductInventory(id, params) : undefined);

export const getProductInstanceByProductId = (
  id: string,
  params?: GetPaginatedProductInventoryOptionalParams,
): Promise<GetPaginatedProductInventoryResponse> =>
  HttpClient.getPaginatedProductInventory(id, params);

/* get product instances quantities */
// const getProductInstancesQuantities: typeof HttpClient.getPaginatedProductInventory = (id, params) =>
//   HttpClient.getPaginatedProductInventory(id, params);

/* get product instance quantites */
export const getQuantities: typeof HttpClient.getPaginatedProductInventory = (params) =>
  HttpClient.getPaginatedProductInventory(params);

/* get container instances */
const getContainerInstancesQuantities = async (
  containerId: string,
  params?: GetContainerInventoryOptionalParams,
) => {
  if (containerId) {
    const preInvItem = getInventoryItem();
    const actionInvItem = shipActionInvItem();
    const rowSpecificData = actionInvItem?.find((r) => r?.containerId === containerId);

    // updated inv item from inventory to get row specific data
    const invItem = preInvItem || rowSpecificData;
    const containerInventory = await HttpClient.getContainerInventory(containerId, params);
    const containerIdentifier = invItem?.containerIdentifier;

    return { ...containerInventory, containerId, containerIdentifier };
  }
  return undefined;
};

/* get product instance */
const getProductInstance = async (
  id: string,
  params?: GetPaginatedProductInventoryOptionalParams,
) => {
  const instances = await getProductInstances(id, params);
  return instances?.results?.[0] || undefined;
};

// export const useProductInstance = (id: string, params?: GetProductInstanceOptionalParams) =>
//   useQuery(['productInstance', id, params], () => getProductInstance(id, params));

/* get product by id */
const getProductById = (
  id: string,
  options?: GetProductByIdOptionalParams,
): Promise<GetProductByIdResponse> => HttpClient.getProductById(id, options);

/**
 *
 * @param id  product id
 * @returns product data from cache if available, otherwise fetches from server
 */
export const getCachedProductById = async (id: string, options?: GetProductByIdOptionalParams) => {
  const cachedProduct = qClient.getQueryData<GetProductByIdResponse>(['product', id, options]);
  if (!cachedProduct) {
    return getProductById(id);
  }
  return cachedProduct;
};

/* create a product */
const createProductRequest = (product: CreateProductRequest): Promise<CreateProductResponse> =>
  HttpClient.createProduct({
    body: product,
  });

/* update a product */
const updateProductRequest = (id: string, product: UpdateProductRequest) =>
  HttpClient.updateProduct(id, { body: product });

/* archive a product */
const changeArchivedProduct = (id: string, body: ChangeArchivedProductRequest) =>
  HttpClient.changeArchivedProduct(id, { body });

export const updateMultipleProductsAsync = async (products: Array<UpdateProductRequest>) => {
  // promise should happen in a loop
  products.forEach(async (product) => {
    await updateProductRequest(product?.id || '', product);
    qClient.invalidateQueries(['product', product.id]);
  });
  return products;
};
export const changeArchiveMultipleProductsAsync = async (
  products: Array<
    UpdateProductRequest & {
      archive?: boolean;
    }
  >,
) => {
  // promise should happen in a loop
  products.forEach(async (product) => {
    const reqBody: ChangeArchivedProductRequest = {
      archived: product.archive || false,
    };
    await changeArchivedProduct(product?.id || '', reqBody);
    qClient.invalidateQueries(['product', product.id]);
  });
  qClient.invalidateQueries('products');
  qClient.invalidateQueries(['subProducts']);
  return products;
};

/* create sub product */
export const createSubProduct = async (params: CreateSubProductRequest) =>
  HttpClient.createSubProduct(params?.parentProductId || '', {
    body: {
      subProductIds: params.productIds,
    },
  });

/* create multiple sub products */
export const createSubProducts = async (params: Array<CreateSubProductRequest>) => {
  const promises = params?.map((param) => createSubProduct(param)) || [];
  return Promise.all(promises);
};

/* remove sub product */
export const removeSubProduct = async (params: RemoveSubProductRequest) =>
  HttpClient.deleteSubProduct(params?.parentProductId || '', params.productIds?.[0] || '');

/* remove sub products */
export const removeSubProducts = async (params: Array<RemoveSubProductRequest>) => {
  const promises = params?.map((param) => removeSubProduct(param)) || [];
  return Promise.all(promises);
};

export const updateSubProducts = async (params: UpdateSubProductsRequest) => {
  const product = params?.product;
  const formdata = params?.body;
  const updatedSubproducts = formdata?.subProducts;

  /* find removed subproducts by comparing formdata with product */
  const rSubProducts = product?.subProducts?.filter((subProduct) => {
    const found = updatedSubproducts?.find((el) => el?.id === subProduct?.id);
    return !found;
  });
  const removeRequests =
    rSubProducts?.map((subProduct) => ({
      parentProductId: product?.id,
      productIds: [subProduct?.id || ''],
    })) || [];

  if (removeRequests?.length > 0) {
    await removeSubProducts(removeRequests);
  }

  /* find added subproducts by comparing formdata with product */
  const aSubProducts = updatedSubproducts?.filter((subProduct) => {
    const found = product?.subProducts?.find((el) => el?.id === subProduct?.id);
    return !found;
  });
  const addRequests =
    aSubProducts?.map((subProduct) => ({
      parentProductId: product?.id,
      productIds: [subProduct?.id || ''],
    })) || [];

  if (addRequests?.length > 0) {
    await createSubProducts(addRequests);
  }
};
/* queries */

/* create product query */
export const useCreateProduct = (queryClient: QueryClient) =>
  useMutation((product: CreateProductRequest) => createProductRequest(product), {
    onSuccess: () => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries(['subProducts']);
    },
  });

/* update product query */
export const useUpdateProductById = (queryClient: QueryClient) =>
  useMutation(
    ({ productId, product }: { productId: string; product: UpdateProductRequest }) =>
      updateProductRequest(productId, product),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('products');
        queryClient.invalidateQueries(['subProducts']);
        queryClient.invalidateQueries(['product', data.id]);
        queryClient.invalidateQueries('productInstanceQuantities');
        queryClient.invalidateQueries('poSearchResults');
      },
    },
  );
/* archive product query */
export const useChangeArchiveProductById = (queryClient: QueryClient) =>
  useMutation(
    ({ productId, body }: { productId: string; body: ChangeArchivedProductRequest }) =>
      changeArchivedProduct(productId, body),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('products');
        queryClient.invalidateQueries(['subProducts']);
        queryClient.invalidateQueries(['product', data.id]);
      },
    },
  );

export const useAddSubProduct = (queryClient: QueryClient) =>
  useMutation((params: CreateSubProductRequest) => createSubProduct(params), {
    onSuccess: (data, params) => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries(['subProducts']);
      queryClient.invalidateQueries(['product_inbound']);
      queryClient.invalidateQueries(['product', params.parentProductId]);
      // queryClient.invalidateQueries(['product_inbound']);
    },
  });

export const useAddSubProducts = (queryClient: QueryClient) =>
  useMutation((params: Array<CreateSubProductRequest>) => createSubProducts(params), {
    onSuccess: (data, params) => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries(['subProducts']);
      queryClient.invalidateQueries(['product_inbound']);
      queryClient.invalidateQueries(['product', params?.[0]?.parentProductId]);
      // queryClient.invalidateQueries(['product_inbound']);
    },
  });

export const useRemoveSubProduct = (queryClient: QueryClient) =>
  useMutation((params: RemoveSubProductRequest) => removeSubProduct(params), {
    onSuccess: (data, params) => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries(['subProducts']);
      queryClient.invalidateQueries(['product_inbound']);
      queryClient.invalidateQueries(['product', params.parentProductId]);
      // queryClient.invalidateQueries(['product_inbound']);
    },
  });

export const useRemoveSubProducts = (queryClient: QueryClient) =>
  useMutation((params: Array<RemoveSubProductRequest>) => removeSubProducts(params), {
    onSuccess: (data, params) => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries(['subProducts']);
      queryClient.invalidateQueries(['product_inbound']);
      queryClient.invalidateQueries(['product', params?.[0]?.parentProductId]);
      // queryClient.invalidateQueries(['product_inbound']);
    },
  });

export const useUpdateSubproducts = (queryClient: QueryClient) =>
  useMutation((params: UpdateSubProductsRequest) => updateSubProducts(params), {
    onSuccess: (data, params) => {
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries(['subProducts']);
      queryClient.invalidateQueries(['product_inbound']);
      queryClient.invalidateQueries(['product', params?.product?.id]);
      // queryClient.invalidateQueries(['product_inbound']);
    },
  });

/* get product by id query */
export const useProductById = (
  id: string,
  options?: GetProductByIdOptionalParams,
  enabled?: boolean,
) =>
  useQuery(['product', id, options], () => (id ? getProductById(id, options) : undefined), {
    retry: false,
    enabled,
  });

/* get multiple product by id query */
export const useMultipleProductsById = (productIdArray: Array<string>) =>
  useQuery(['products', ...productIdArray], () =>
    Promise.all(productIdArray.map((id) => getProductById(id))),
  );

/* get product instances by productId query */
export const useProductInstances = (
  id?: string,
  params?: GetPaginatedProductInventoryOptionalParams,
) => useQuery(['productInstances', id, params], () => getProductInstances(id || '', params));

/* get product instances quantities query */
export const useProductInstanceQuantities = (
  id: string,
  params?: GetPaginatedProductInventoryOptionalParams,
  enabled?: boolean,
) =>
  useQuery(['productInstanceQuantities', id, params], () => getProductInstances(id, params), {
    enabled,
  });
/* get product  quantities query */
export const useInstanceQuantities = (
  id: string,
  params?: GetPaginatedProductInventoryOptionalParams,
) =>
  useQuery(['productInstancesQuantities', params], () => getProductInstances(id, params), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

/* get product container instances query */
export const useProductContainerInstances = (
  containerId: string,
  params?: GetContainerInventoryOptionalParams,
) =>
  useQuery(['containerInstances', containerId, params], () =>
    getContainerInstancesQuantities(containerId, params),
  );

// need some help understanding this
const getInstancesOrContainers = async (
  instanceArray: Array<string>,
  containerArray?: Array<string>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  params?: GetPaginatedProductInventoryOptionalParams,
) => {
  /* clean container array and remove empty strings */
  const cleanContainerArray = containerArray?.filter((id) => id !== '') || [];
  /* filter out instances from containers */
  const filteredInstanceArray = instanceArray.filter(
    (instance) => !cleanContainerArray?.includes(instance),
  );
  const mainProductId = window?.location?.pathname?.split('/')?.[2];
  const initParams: GetPaginatedProductInventoryOptionalParams = {
    ...params,
    pageNumber: 1,
  };

  const initData = qClient?.getQueryData<PaginatedInventoryResponse | undefined>([
    'productInstanceQuantities',
    mainProductId,
    initParams,
  ]);

  let cachedProductInstances: InventoryResponse[] | undefined = [...(initData?.results || [])];
  for (let i = 2; i <= (initData?.totalPages || 2); i += 1) {
    const quantitesParams: GetPaginatedProductInventoryOptionalParams = {
      ...params,
      pageNumber: i,
    };
    const data = qClient?.getQueryData<PaginatedInventoryResponse | undefined>([
      'productInstanceQuantities',
      mainProductId,
      quantitesParams,
    ]);
    cachedProductInstances = [...cachedProductInstances, ...(data?.results || [])];
  }

  const missingIds = filteredInstanceArray?.filter((id) => {
    const result = cachedProductInstances?.find((el) => el?.productInstance?.id === id);
    return !result;
  }, []);

  if (missingIds?.length) {
    const missingInstances = await getProductInstances(mainProductId, {
      instanceIds: missingIds,
    });

    cachedProductInstances = [...cachedProductInstances, ...(missingInstances?.results || [])];
  }

  const instancePromises =
    filteredInstanceArray?.map((id) => {
      const instanceQuantity = cachedProductInstances?.find((el) => el?.productInstance?.id === id);
      if (instanceQuantity) {
        return Promise.resolve<InventoryResponse>(instanceQuantity);
      }

      return getProductInstance(mainProductId, {
        instanceIds: [id],
      });
    }) || [];
  const containerPromises =
    cleanContainerArray?.map((id) =>
      getContainerInstancesQuantities(id, { pageNumber: 1, pageSize: 9999 }),
    ) || [];

  const promises = [...instancePromises, ...containerPromises]?.filter((p) => p) || [];
  return Promise.all(promises?.filter((p) => p));
};

/* get multiple product instances query */
export const useMultipleProductInstances = (
  instanceArray: Array<string>,
  containerArray?: Array<string>,
  params?: GetPaginatedProductInventoryOptionalParams,
) =>
  useQuery(
    ['productInstance', ...instanceArray, ...(containerArray || []), params],
    () => getInstancesOrContainers(instanceArray, containerArray, params),
    {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnReconnect: false,
    },
  );

/* get products query */
const useProducts = (filters?: GetPaginatedProductsOptionalParams) =>
  useQuery(['products', filters], () => getProducts(filters));

/* productss filter for table */
export const useProductsTableFilters = (
  params?: GetPaginatedProductsOptionalParams,
): ProColumns['filters'] => {
  const { data: products } = useProducts(params);
  const productFilters = useMemo(
    () =>
      products?.results?.map((option) => ({
        text: option?.name || '',
        value: option?.id || '',
      })) || [],
    [products],
  );
  return productFilters;
};

/* get product instance by selected product id */
export const useGetProductInstanceById = (queryClient: QueryClient) =>
  useMutation(
    ({ id, params }: { id: string; params?: GetPaginatedProductInventoryOptionalParams }) =>
      getProductInstanceByProductId(id, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('products');
      },
    },
  );

const getGlobalSearchPaginatedProducts = (
  options?: GetGlobalSearchPaginatedProductsOptionalParams,
): Promise<GetGlobalSearchPaginatedProductsResponse> | undefined =>
  options?.purchaseOrder ? httpClient?.getGlobalSearchPaginatedProducts(options) : undefined;

export const usePOSearch = (options?: GetGlobalSearchPaginatedProductsOptionalParams) =>
  useQuery(['poSearchResults', options], () => getGlobalSearchPaginatedProducts(options));

export default useProducts;
